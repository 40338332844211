<template>
  <div
    class="xpp-slide-sidebar"
    :class="slideSidebarOpen ? 'slide-open' : 'slide-close'"
  >
    <div class="slides-header">
      <div
        class="slide-header-btn iconfont icon-slides-nav-icon"
        @click.stop="paperCatalogCheck"
      ></div>
      <div
        class="slide-header-btn iconfont icon-slides-fullscreen-icon"
        @click="xppSlideShow"
      ></div>
      <div
        class="slide-header-btn iconfont icon-new_page"
        @click="createPaperPage(0)"
      ></div>
      <div
        class="slide-header-btn iconfont icon-slides-hide-icon"
        :class="{ active: slideSidebarOpen }"
        @click="slidesHide"
      ></div>
    </div>
    <div id="xppSlidesContainer" v-if="paperViewAll.length">
      <Draggable
        v-model="viewList"
        v-bind="paperDragOptions"
        class="xpp-slide-drag"
        @start="dragStart"
        @end="dragEnd"
      >
        <transition-group
          tag="div"
          type="transition"
          :name="!drag ? 'flip-list' : null"
        >
          <PageThumbnail
            v-for="(paper, index) in paperViewAll"
            :key="paper.page_id"
            :pageIndex="index"
            :pageInfo="paper"
            :xAcIdx="xAcIdx"
          />
        </transition-group>
      </Draggable>
      <div class="xpp-slide-create-page" @click="createPaperPage">
        <svg width="12" height="12" viewBox="0 0 12 12" class="svg-icon">
          <path
            fill="#C9D0D5"
            fill-rule="evenodd"
            d="M5 5V1a1 1 0 1 1 2 0v4h4a1 1 0 1 1 0 2H7v4a1 1 0 1 1-2 0V7H1a1 1 0 1 1 0-2h4z"
          ></path>
        </svg>
      </div>
    </div>

    <!-- paper Catalog -->
    <PaperCatalog :list="paperViewAll" v-show="paperCatalogShow" />

    <!-- paper SlidePlayer -->
    <div
      id="xppSlidePlayerToolbar"
      ref="slidePlayerToolbar"
      @click="playerToolbarOption"
      v-show="slidesPlayInfo.state"
      data-event="next"
    >
      <div>
        <i class="el-icon-caret-left" data-event="prev"></i>
        <span>{{ slidesPlayInfo.index + 1 }} / {{ slidesPlayInfo.total }}</span>
        <i class="el-icon-caret-right" data-event="next"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import PageThumbnail from "@components/PageThumbnail";
import PaperCatalog from "@components/PaperCatalog";
import Draggable from "vuedraggable";
import createPage from "@plugins/createPage";

export default {
  name: "SlideSidebar",
  props: {
    xAcIdx: {
      type: Number,
      required: true
    }
  },
  mixins: [createPage],
  components: {
    Draggable,
    PageThumbnail,
    PaperCatalog
  },
  computed: {
    viewList: {
      get() {
        return this.paperViewAll;
      },
      set(val) {
        this.dataChange = true;
        this.$SetPaperViewAll(val);
      }
    },
    ...mapGetters([
      "paperGroupId",
      "slideSidebarOpen",
      "paperViewAll",
      "paperPageInfo",
      "paperViewZoomInfo",
      "homeViewScale",
      "paperDragOptions"
    ])
  },
  data() {
    return {
      paperContainer: null,
      drag: false,
      dataChange: false,
      paperCatalogShow: false,
      slidesPlayInfo: {
        state: false,
        index: 0,
        total: 0,
        container: null
      },
      slidesScale: 1,
      paperPageDomList: []
    };
  },
  methods: {
    viewPageSort() {
      this.dataChange = false;
      this.$PaperPageSort();
    },
    slidesHide() {
      this.$UpdataVuexState({
        key: "slideSidebarOpen",
        data: !this.slideSidebarOpen
      });
    },
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
      this.dataChange && this.viewPageSort();
    },
    paperCatalogCheck() {
      this.paperCatalogShow = !this.paperCatalogShow;
      if (this.paperCatalogShow) {
        this.$nextTick(() => {
          window.addEventListener("click", this.viewClick);
        });
      }
    },
    viewClick({ target }) {
      let off = document
        .getElementById("paperCatalogContainer")
        .contains(target);
      if (!off) {
        this.paperCatalogShow = false;
        window.removeEventListener("click", this.viewClick);
      }
    },
    playerKeyDownOption(event) {
      event.preventDefault();
      event.stopPropagation();
      switch (event.keyCode) {
        case 37:
        case 38:
          this.showPaperPageIndex(this.slidesPlayInfo.index - 1);
          break;
        case 39:
        case 40:
          this.showPaperPageIndex(this.slidesPlayInfo.index + 1);
          break;
      }
    },
    playerToolbarOption({ target }) {
      switch (target.dataset.event) {
        case "prev":
          this.showPaperPageIndex(this.slidesPlayInfo.index - 1);
          break;
        case "next":
          this.showPaperPageIndex(this.slidesPlayInfo.index + 1);
          break;
      }
    },
    async xppSlideShow() {
      let {
        clientWidth: viewWidth,
        clientHeight: viewHeight
      } = document.documentElement;

      let parentContainer = document.getElementById("paperContainer");
      parentContainer.classList.add("xpp-slide-show");
      this.slidesPlayInfo.container = parentContainer;
      parentContainer.appendChild(this.$refs.slidePlayerToolbar);
      Object.assign(this.slidesPlayInfo, {
        old_scale: this.homeViewScale,
        old_scroll: parentContainer.scrollTop,
        viewWidth,
        viewHeight
      });
      parentContainer.onfullscreenchange = () => {
        if (!document.fullscreenElement) {
          this.slideViewSizeRemove();
        }
      };
      await this.toFullVideo(parentContainer);
      this.$ChangeSlidePlayerState(2);
      setTimeout(() => {
        this.slideViewSizeInit();
      }, 1500);
    },
    slideViewSizeInit() {
      this.$ChangeSlidePlayerState(3);
      let {
        clientWidth: viewWidth,
        clientHeight: viewHeight
      } = document.documentElement;
      let { width, height } = this.paperViewZoomInfo;
      height -= 120;
      viewHeight -= 56;
      if (width >= viewWidth || height >= viewHeight) {
        if (width >= height) {
          this.slidesScale = Math.floor((viewWidth / width) * 100) / 100;
          if (height * this.slidesScale >= viewHeight) {
            this.slidesScale = Math.floor((viewHeight / height) * 100) / 100;
          }
        } else {
          this.slidesScale = Math.floor((viewHeight / height) * 100) / 100;
          if (width * this.slidesScale >= viewWidth) {
            this.slidesScale = Math.floor((viewWidth / width) * 100) / 100;
          }
        }
      }
      this.$SetViewScale(this.slidesScale);
      this.paperPageDomList = document.getElementsByClassName("xpp-paper-page");
      this.slidesPlayInfo.total = this.paperPageDomList.length;
      this.slidesPlayInfo.state = true;
      this.slidesPlayInfo.conPaddingTop =
        (viewHeight - height * this.slidesScale) / 2 + "px";
      this.showPaperPageIndex(this.paperPageInfo.page_index);
      window.addEventListener("keydown", this.playerKeyDownOption);
    },
    slideViewSizeRemove() {
      window.removeEventListener("keydown", this.playerKeyDownOption);
      let { container, old_scroll, old_scale } = this.slidesPlayInfo;
      this.slidesPlayInfo.state = false;
      document.fullscreenElement && document.exitFullscreen();
      container.style.paddingTop = null;
      container.classList.remove("xpp-slide-show");
      container.scrollTo(0, old_scroll);
      document
        .getElementsByClassName("xpp-slide-sidebar")[0]
        .appendChild(this.$refs.slidePlayerToolbar);
      this.$ChangeSlidePlayerState(1);
      let playPage = document.getElementsByClassName("xpp-active-play")[0];
      playPage && playPage.classList.remove("xpp-active-play");
      this.$SetViewScale(old_scale);
    },
    showPaperPageIndex(index) {
      let { container, total, conPaddingTop } = this.slidesPlayInfo;
      if (index >= total) {
        return this.slideViewSizeRemove();
      }
      if (index < 0) {
        return;
      }
      let playPage = document.getElementsByClassName("xpp-active-play")[0];
      playPage && playPage.classList.remove("xpp-active-play");
      this.paperPageDomList[index].classList.add("xpp-active-play");
      if (this.slidesScale !== this.homeViewScale) {
        container.style = null;
      } else {
        container.style.paddingTop = conPaddingTop;
      }
      container.scrollTo(0, 0);
      this.slidesPlayInfo.index = index;
    },
    toFullVideo(videoDom) {
      if (videoDom.requestFullscreen) {
        return videoDom.requestFullscreen();
      } else if (videoDom.webkitRequestFullScreen) {
        return videoDom.webkitRequestFullScreen();
      } else if (videoDom.mozRequestFullScreen) {
        return videoDom.mozRequestFullScreen();
      } else {
        return videoDom.msRequestFullscreen();
      }
    },
    ...mapMutations([
      "$UpdataVuexState",
      "$SetViewScale",
      "$SetPaperViewAll",
      "$ChangeSlidePlayerState"
    ]),
    ...mapActions(["$WsSendMsg", "$PaperPageSort"])
  }
};
</script>

<style lang="scss" scoped>
.xpp-slide-sidebar {
  position: relative;
  flex: none;
  width: $slide-sidebar-width;
  height: 100%;
  box-shadow: -5px 23px 40px 0 #dfdfe0;
  background-color: #fff;
  transition: width 0.3s;

  &.slide-open {
    width: $slide-sidebar-active;
  }

  .slides-header {
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #e9ebec;
  }

  .slide-header-btn {
    flex: none;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    cursor: pointer;
    color: #535761;
    &:hover {
      background-color: #ebeef2;
    }
  }

  .icon-slides-hide-icon {
    font-size: 13px;
    transition: all 0.3s;
    transform: rotateY(180deg);

    &.active {
      transform: rotateY(0deg);
    }
  }
  .icon-slides-nav-icon {
    font-size: 12px;
  }
}

#xppSlidesContainer {
  position: relative;
  height: calc(100% - 32px - 30px);
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 0.1px;
}

.flip-list-move {
  transition: transform 0.5s;
}
.xpp-slide-create-page {
  height: 87px;
  border-radius: 4px;
  border: 2px dashed #c9d0d5;
  line-height: 87px;
  width: 122px;
  text-align: center;
  margin-left: 26px;
  margin-bottom: 30px;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
}

#xppSlidePlayerToolbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 3;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
  div {
    position: relative;
    z-index: 2;
    margin: auto;
    height: 54px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i {
    cursor: pointer;
    color: #666;
    font-size: 1.8em;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    &:hover {
      color: #ccc;
    }
  }
  span {
    flex: none;
    height: 34px;
    line-height: 34px;
    font-size: 1.2em;
    color: #ccc;
    width: 82px;
    text-align: center;
  }
}
</style>
<style lang="scss">
.slide-close {
  #xppSlidesContainer {
    .xpp-slides-main {
      padding-bottom: 0;
      margin-bottom: 0;
      padding: 4px;
    }
    .xpp-slides-page-tit,
    .slides-list-index,
    .xpp-slide-create-page {
      display: none;
    }
    .page-thumbnail-box {
      opacity: 0;
    }
    .xpp-slides-page-body {
      padding-right: 0;
    }
    .slides-list-item {
      height: 40px !important;
      box-shadow: 0px 0px 0 2px #e1e2e9;
      &.slides-list-active {
        box-shadow: 0px 0px 0 2px #f5a623;
      }
    }
  }
}

// 幻灯片放映
#xpaper {
  .xpp-slide-show {
    padding: 0;
    overflow: hidden;
    #canvasWrapper {
      margin-top: 0 !important;
      margin-bottom: 100vh;
    }
    .xpp-paper-page {
      margin-bottom: 0;
    }
    .xpp-paper-page,
    .paper-add-page-box,
    .xpp-paper-head,
    .ui-resizable-handle {
      display: none;
    }
    .xpp-active-play {
      display: block !important;
    }
  }
}
</style>
