import { mapMutations } from "vuex";

export default {
  data() {
    return {
      xcanvasAcIdx: 8,
      intersectionOp: {},
      intersectionTime: 0,
      intersectionNodes: null
    };
  },
  methods: {
    intersectionFb(io, entries) {
      let maxRatio = 0;
      let acIndex = null;
      for (let i = 0; i < entries.length; i++) {
        let item = entries[i];
        if (item.intersectionRatio > 0) {
          io.unobserve(item.target);
        }
        if (item.intersectionRatio > maxRatio) {
          maxRatio = item.intersectionRatio;
          acIndex = Math.max(item.target.dataset.index - 0, acIndex);
        }
      }
      if (acIndex === null) return;
      this.xcanvasAcIdx = Math.max(this.xcanvasAcIdx, acIndex + 4);
      console.log("xcanvasAcIdx: " + this.xcanvasAcIdx);
    },
    countPageloadIdx(nodes) {
      let io = new IntersectionObserver(entries => {
        this.intersectionFb(io, entries);
      }, this.intersectionOp);
      for (let i = 0; i < nodes.length; i++) {
        io.observe(nodes[i]);
      }
    },
    ...mapMutations(["$SetPaperPageInfo"])
  }
};
