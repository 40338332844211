<template>
  <div id="xcanvasView" :class="xppWritable" v-loading="!xcanvasReadyState">
    <PaperHead />
    <!-- 左侧工具栏 -->
    <PaperToolbar
      @toolbarNotification="toolbarNotification"
      @creatLink="xppCrearLinkNode"
    />
    <!-- 画笔工具栏 -->
    <SvgToolbar @back="backRemovePath" />
    <!-- 便签背景选择工具栏 -->
    <NoteBgToolbar />
    <!-- 缩放控制台 -->
    <PaperViewZoom ref="PaperViewZoom" @operation="operationRevoke" />
    <!-- 富文本工具栏 -->
    <div
      id="xppToolbarContainer"
      @click="xppToolbarConfigLog"
      v-show="dialogElementShow.xppEditToolbarState"
    ></div>
    <!-- 托选文件时遮罩 -->
    <div id="ItalyCannon" v-if="dragFileEnterOff">
      <div id="dropFileHelper" ref="dropFileHelper"></div>
    </div>
    <!-- 文件上传进度 -->
    <div id="dropFileProgress" class="display-flex" v-if="fileProgress">
      <div>
        <div
          class="xpp-file-progress-close iconfont icon-baseline-close-px"
          @click="fileUpStop"
        ></div>
        <div class="xpp-file-progress-tit text-overflow">
          {{ fileProgressInfo.fileName }}
        </div>
        <div class="xpp-file-progress-body display-flex">
          <span>正在上传...</span
          ><em
            >({{ fileProgressInfo.itemIndex }}/{{ fileProgressInfo.total }})</em
          >
          <div class="xpp-file-progress-box">
            <div class="xpp-file-progress-load" :style="fileProgressLoad"></div>
          </div>
          <div class="xpp-file-progress-scale">
            {{ fileProgressInfo.scale }}%
          </div>
        </div>
      </div>
    </div>
    <div class="paper-container">
      <div
        id="paperContainer"
        class="paper-main"
        :class="cursorCommentActive"
        ref="paperContainer"
        v-loading="xppSlidePlayerReady === 2"
        @contextmenu="paperContextMenu"
        @scroll="paperScroll"
        @mousewheel="paperMousewheel"
        @mousemove="paperMousemove"
        @dragenter="fileDragenter"
        @dragleave="fileDragleave"
        @dragover="fileDragover"
        @drop="fileDrop"
      >
        <div id="canvasWrapper" :style="offsetParent">
          <div id="canvasContainer" :style="viewRatio">
            <PaperPage
              v-for="(paper, index) in paperViewAll"
              :key="paper.page_id"
              ref="xppPaperPage"
              :paperInfo="paper"
              :paperPageId="paper.page_id"
              :paperPageIndex="index"
              :paperPageSize="paperPageViewSize"
              :xAcIdx="xcanvasAcIdx"
              @updata="updataPaperInfo"
              @updataGrid="updataGrid"
            />
          </div>
          <!-- 新建page -->
          <div class="paper-add-page-box display-flex">
            <div class="paper-add-page-btn" @click="createPaperPage">
              <i class="iconfont icon-new_page"></i>
              <span>新建页面</span>
            </div>
          </div>
          <!-- 评论 -->
          <ElementComment />
        </div>
      </div>
      <!-- 拖选框 -->
      <div id="elementCheckBox" ref="elementCheckBox"></div>
      <!-- 侧边栏 - 缩略图 -->
      <SlideSidebar :xAcIdx="xcanvasAcIdx" />
    </div>
    <!-- 文件预览 -->
    <template v-if="showFilePreview">
      <FilePreview />
    </template>
    <!-- 文件选择器 -->
    <form name="xppUpdataFiles">
      <input
        id="updataFiles"
        ref="updataFiles"
        type="file"
        multiple
        @change="formUpdataFiles"
      />
    </form>
    <!-- 修改人及时间信息 -->
    <div
      id="modifiedInfoFixed"
      class="display-flex"
      :v-if="modifiedIf"
      :class="modifiedInfoShow"
      :style="modifiedInfo.style"
    >
      <div
        class="xpp-user-photo"
        :style="modifiedInfo.headImg | styleUserPhoto"
      ></div>
      <div>
        <p class="text-overflow">
          <b>{{ modifiedInfo.nickName }}</b>
        </p>
        <p class="text-overflow">
          <span>{{ modifiedInfo.time | modifiedTime(true) }}</span>
        </p>
      </div>
    </div>
    <!-- 右键菜单 -->
    <PaperContextMenu @menuback="xppMenuBack" />
    <!-- 修改版本历史 -->
    <EditHistoryLog />
    <!-- @联系人列表 -->
    <XppContactList />
    <!-- 图片预览 -->
    <PicturePreview />

    <!-- 密码保护 -->
    <template v-if="xppPaperPassword.show">
      <PaperPassword :type="2" @back="paperPasswordCheck" />
    </template>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  aliyunPut,
  aliyunPutStream,
  aliyunStop,
  aliyunPutThumb
} from "@plugins/aliyunOss";

import viewMixin from "@plugins/viewMixin";
import contextMenu from "@plugins/contextMenu";
import pageLazyload from "@plugins/pageLazyload";

import PaperHead from "@components/layout/PaperHead";
import PaperToolbar from "@components/PaperToolbar";
import PaperPage from "@components/PaperPage";
import SlideSidebar from "@components/SlideSidebar";
import PaperViewZoom from "@components/PaperViewZoom";
import PicturePreview from "@components/PicturePreview";

import {
  parentLocation,
  creatElementId,
  formParamsFormat
} from "@plugins/toolWheel";

const VailUrlReg = /^(https?:\/\/)|mailto|tel/;

export default {
  name: "Paper",
  props: {
    sharePaperId: {
      type: Number,
      required: false
    }
  },
  beforeRouteUpdate(target, source, next) {
    document.documentElement.click();
    this.$UpdataVuexState({ key: "xcanvasReadyState", data: false });
    setTimeout(() => {
      this.init(target.params.id);
      next();
    }, 300);
  },
  async beforeRouteLeave(to, from, next) {
    document.documentElement.click();
    this.$UpdataVuexState({ key: "xcanvasReadyState", data: false });
    this.unload();
    next();
  },
  mixins: [viewMixin, contextMenu, pageLazyload],
  components: {
    PaperHead,
    PaperToolbar,
    PaperPage,
    SlideSidebar,
    PaperViewZoom,
    PicturePreview,
    XppContactList: () => import("@components/XppContactList"),
    SvgToolbar: () => import("@components/SvgToolbar"),
    NoteBgToolbar: () => import("@components/NoteBgToolbar"),
    FilePreview: () => import("@components/FilePreview"),
    EditHistoryLog: () => import("@components/EditHistoryLog"),
    ElementComment: () => import("@components/ElementComment"),
    PaperContextMenu: () => import("@components/PaperContextMenu"),
    PaperPassword: () => import("@components/PaperPassword")
  },
  computed: {
    xppWritable() {
      let cc = {};
      switch (this.paperOperateMode) {
        case 1:
          cc["xpp-writable-disabled"] = true;
          break;
        case 3:
          cc["xpp-writable-annotation"] = true;
          break;
      }
      if (this.$route.name === "Share") {
        cc["xpp-share-view"] = true;
      }
      return cc;
    },
    paperPageViewSize() {
      let { width, height } = this.paperViewZoomInfo;
      return {
        width: width + "px",
        height: height + "px"
      };
    },
    paperPageMiddleGrid() {
      return {
        x: this.paperViewZoomInfo.width / 2,
        y: this.paperViewZoomInfo.height / 2
      };
    },
    correctionScale() {
      return 5 / this.homeViewScale;
    },
    fileProgressLoad() {
      return {
        width: this.fileProgressInfo.scale + "%"
      };
    },
    cursorCommentActive() {
      return {
        "xpp-comment-cursor": this.cursorComment
      };
    },
    modifiedIf() {
      if (!this.dialogElementShow) {
        return false;
      }
      let state = true;
      let keys = Object.keys(this.dialogElementShow);
      for (let i of keys) {
        if (this.dialogElementShow[i]) {
          state = false;
        }
      }
      return state;
    },
    modifiedInfoShow() {
      return {
        "modified-show": this.modifiedInfo.show
      };
    },
    ...mapGetters([
      "xcanvasReadyState",
      "xppUserInfo",
      "paperGroupId",
      "paperViewAll",
      "paperActiveDom",
      "homeViewScale",
      "paperViewZoomInfo",
      "paperPageInfo",
      "activeRemovePath",
      "paperElementDropStart",
      "paperPageNodeGrid",
      "dragFileEnterOff",
      "dialogElementShow",
      "lastEventCoordinate",
      "showFilePreview",
      "paperOperateMode",
      "xppOperationRecord",
      "xppSlidePlayerReady",
      "xppPaperPassword"
    ])
  },
  data() {
    return {
      paperId: null, // paper id
      scrollShakeDelay: null,
      selectLoopStatus: true, // 多选节点事件处理状态
      formFiles: [], // 文件列表
      dropEventInfo: {}, // 鼠标坐标记录
      fileProgress: false, // 进度信息显示
      // 文件上传进度信息
      fileProgressInfo: {
        fileName: "",
        itemIndex: 1,
        total: 0,
        scale: 0
      },
      cursorComment: false,
      // 节点更改人及时间信息
      modifiedInfo: {
        show: false,
        style: null,
        targetId: null,
        id: "",
        time: 0,
        headImg: "" // 修改人头像
      },
      prevMousemoveTg: null, // 上一次鼠标移动时经过的节点
      // 鼠标移动轨迹记录
      trajectoryHistory: {
        page: null,
        timeStamp: 0,
        rect: [],
        timeFb: null // 如果存在轨迹，每隔一段时间清空并发送信息
      },
      currentPapperInfo: {}, // 当前paper 类目信息
      paperMousemoveEvent: null // 鼠标移动事件对象
    };
  },
  mounted() {
    // 事件绑定
    this.keysBind();

    // 数据更新
    if (!this.sharePaperId) {
      this.paperId = this.$route.params.id;
    } else {
      this.paperId = this.sharePaperId;
    }
    this.init(this.paperId);

    // 离开网页(下载文件也算离开此网站)
    window.addEventListener("beforeunload", () => {
      event.preventDefault();
      document.documentElement.click();
      return null;
    });
  },
  methods: {
    init(page_id) {
      this.$SetPaperGroupId(page_id);
      this.paperPasswordCheck();
    },
    unload() {
      this.$OnlineStateChange();
      this.keysRemoveEvent();
      // 重置部分数据
      this.$DiaLoginFoUpDate();
      this.$SetPaperViewAll([]);
      this.$UpdataVuexState({ key: "homeViewScale", data: 0 });
    },
    async paperPasswordCheck(password) {
      let visitKey = this.$route.params.info;
      let params = {
        id: this.paperGroupId,
        resource_type: 2,
        password,
        visitKey
      };
      let paperInfo = await this.$axios.get("/paper/get", {
        params
      });

      if (paperInfo.status !== 200) {
        localStorage.setItem(
          "XppErrMessage",
          JSON.stringify({
            error: paperInfo,
            title: "地址不存在",
            msg: "请确认您输入的地址无误",
            paperId: this.paperGroupId
          })
        );
        this.$router.replace("/error_view/" + paperInfo.status);
        return;
      }
      switch (paperInfo.pwd_error_code) {
        case 200:
          this.paperConInfo(paperInfo);
          break;
        case 440: // 需要输入密码
        case 441: // 密码错误
          this.$UpdataVuexState({
            key: "xppPaperPassword",
            data: {
              show: true,
              ispwd: true,
              state: 1,
              founderInfo: paperInfo.member
            }
          });
          break;
        case 442: // 用户权限准许跳过密码校验
          this.$UpdataVuexState({
            key: "xppPaperPassword",
            data: {
              ispwd: true
            }
          });
          this.paperConInfo(paperInfo);
          break;
        default:
          console.log("意料之外的状态码", paperInfo.pwd_error_code);
          break;
      }
    },
    // 获取paper内数据
    async paperConInfo(info) {
      if (info.status !== 200) return;
      if (!info.list.length) {
        this.$SetPaperViewAll([]);
        return this.$notify.error({
          title: "错误",
          message: "当前Paper下没有任何数据",
          duration: 0,
          onClose: () => {
            this.$router.push({ name: "HomeProject" });
          }
        });
      }

      // 连接ws
      this.$PaperWebSocket();

      // 存储页面尺寸信息
      this.$UpdataVuexState({
        key: "paperViewZoomInfo",
        data: info.pageSize
      });

      // 设置页面缩放比例
      this.viewChange(info.pageSize);

      //  paper 路径信息
      let { category, ownerInfo = {}, paper = {} } = info;
      // 当前paper类目信息
      this.currentPapperInfo = category.slice(-1)[0];
      // 修改title
      document.title = this.currentPapperInfo.name;
      this.$UpdataVuexState({
        key: "paperCategoryList",
        data: category
      });

      // 存储paper数据
      let repeatNode = [];
      this.$SetPaperViewAll(
        info.list.map(item => {
          let keys = Object.keys(item).filter(
            item => item.indexOf("Sequence") > 0
          );
          let { page_id } = item;
          for (let i in keys) {
            let sequence = item[keys[i]];
            let sKeys = Object.keys(sequence);
            if (!sKeys.length) continue;
            for (let o in sKeys) {
              let tg = sequence[sKeys[o]];
              if (tg.page_id !== page_id) {
                repeatNode.push({
                  id: sKeys[o],
                  page_id: tg.page_id
                });
                delete sequence[sKeys[o]];
              }
            }
          }
          return item;
        })
      );

      console.log(repeatNode);

      this.$SetPaperPageInfo(0);
      this.$RemoveXppOperationRecord(); // 清空操作记录

      // 存储paper所有者信息
      this.$UpdataVuexState({
        key: "paperOwnerInfo",
        data: {
          nickName: ownerInfo.nickName,
          updateDate: paper.update_date
        }
      });
      console.timeEnd("xcanvas");

      this.$nextTick(() => {
        let paperPage = document.getElementById(this.paperViewAll[0].page_id);

        this.$UpdateLastEventCoordinate({ event: null, paperPage });

        this.mouseIncidentMonitor(); // 事件绑定
        this.$GetXppAllowanceInfo();
        this.$GetResourceMemberList({
          resourceId: this.paperGroupId,
          resourceType: "2"
        });
        this.countPageloadIdx(
          this.$refs.paperContainer.querySelectorAll(".xpp-paper-dom-box")
        );
        this.scrollHistoricalReview();
        this.$NavToTargetElement();
        this.uploadCanvasThumb();
      });
    },
    // window copy 事件监测
    domCopyBind(event) {
      let clipboardData =
        event.clipboardData || event.originalEvent.clipboardData;
      if (
        (this.paperActiveDom.id || this.paperActiveDom.length) &&
        document.activeElement instanceof HTMLBodyElement
      ) {
        event.preventDefault();
        clipboardData.setData("copy", "xppkeydown");
        this.xppElementDataCopy();
      } else {
        clipboardData.clearData("copy");
      }
    },
    // 由剪贴板创建文字内容
    clipboarCreateTextNode(plain, target) {
      let content = plain.replace(/\</g, "&lt;").replace(/\>/g, "&gt;");
      console.log(content);
      // url 类型的文字
      if (VailUrlReg.test(content)) {
        this.xppCrearLinkNode({
          top: this.paperMousemoveEvent.offsetY + "px",
          left: this.paperMousemoveEvent.offsetX + "px",
          link: content,
          content: ""
        });
        return;
      }
      this.callPaperPageEvent(
        target.id,
        "creatTextNode",
        this.paperMousemoveEvent,
        content
      );
    },
    // 剪贴板内容处理
    clipboardFactory(items, target, clipboardTextPlain) {
      if (!items.length) return;
      let item = items.pop();
      if (!item) return;
      let { kind, type } = item;
      switch (kind) {
        case "file":
          {
            let file = item.getAsFile();
            console.log(kind, type);
            console.log(file);
            if (file) {
              this.fileDrop(
                this.paperMousemoveEvent || {
                  target: document.getElementById(this.paperPageInfo.page_id)
                },
                [file]
              );
            } else {
              return this.clipboardFactory(items, target, clipboardTextPlain);
            }
          }
          break;
        case "string":
          item.getAsString(str => {
            console.log(kind, type);
            switch (type) {
              case "text/html":
                let fragment = str
                  .substring(
                    str.indexOf("<!--StartFragment-->") + 20,
                    str.indexOf("<!--EndFragment-->")
                  )
                  .replace(/\</g, "&lt;")
                  .replace(/\>/g, "&gt;")
                  .trim();
                if (fragment.search(/file:\/\//) >= 0) {
                  return this.$notify.error({
                    title: "您正在尝试让网站读取本地文件，此行为已被浏览器禁止",
                    message:
                      "如需上传文件，请使用拖拽文件上传或通过工具栏的上传文件按钮上传",
                    duration: 6000
                  });
                }
                console.log(fragment);
                let con = document.createElement("div");
                con.innerHTML = fragment;
                let img = con.getElementsByTagName("img")[0];
                // 没有读取到图片文件
                if (!img) {
                  if (clipboardTextPlain) {
                    this.clipboarCreateTextNode(clipboardTextPlain, target);
                  }
                  return;
                }
                this.fileDrop(
                  this.paperMousemoveEvent || {
                    target: document.getElementById(this.paperPageInfo.page_id)
                  },
                  [
                    {
                      copysrc: img.src,
                      name: img.src.split("/").pop(),
                      type: "image/png",
                      length: 1
                    }
                  ]
                );
                break;
              default:
                this.clipboarCreateTextNode(clipboardTextPlain, target);
                break;
            }
          });
          break;
      }
    },
    // window paste粘贴 事件监测
    domPasteBind(event) {
      // 有 dom 未获取焦点
      if (!(document.activeElement instanceof HTMLBodyElement)) {
        return;
      }
      // 没有目标信息
      if (!this.paperPageInfo.page_id || !this.paperMousemoveEvent) {
        return;
      }
      // 无法获取剪贴板内容
      if (!event.clipboardData && !event.originalEvent) return;

      event.preventDefault();
      event.stopPropagation();
      let clipboardData =
        event.clipboardData || event.originalEvent.clipboardData;
      if (clipboardData.getData("copy") === "xppkeydown") {
        return this.xppElementDataPaste();
      }
      let target = parentLocation(
        this.paperMousemoveEvent.target,
        ".xpp-paper-dom-box"
      );
      if (!target) return;
      let { items } = clipboardData;
      console.log(items);
      this.clipboardFactory(
        [...items],
        target,
        clipboardData.getData("text/plain").trim()
      );
    },
    // 调用paperpage组件内事件
    callPaperPageEvent(id, key, event, content = "") {
      switch (event) {
        case "creatTextNode":
          if (content.trim() === "") return; // 不处理空内容
          break;
      }
      let list = this.$refs.xppPaperPage;
      for (let i = 0; i < list.length; i++) {
        let page = list[i];
        let target = page.$el.getElementsByClassName("xpp-paper-dom-box")[0];
        if (target.id === id) {
          page[key](event, content);
          break;
        }
      }
      return false;
    },
    creatPaperFilesNode(
      fileType,
      page_id,
      formData,
      file,
      topRange,
      leftRange
    ) {
      let applicationSuffix = /\.{1}\w+$/.exec(formData.name) || "";
      if (applicationSuffix[0]) {
        applicationSuffix = applicationSuffix[0]
          .replace(/\./, "")
          .toLocaleLowerCase();
      }
      let page_index,
        page_dom = document.getElementById(page_id);
      if (page_dom) {
        page_index = page_dom.dataset.index;
      } else {
        console.warn("文件上传中目标页面丢失");
        return;
      }
      let nodeInfo = {
        id: creatElementId("files"),
        page_index,
        page_id: this.paperViewAll[page_index].page_id,
        src: formData.copysrc
          ? formData.copysrc
          : `https://5gcoolwork.oss-accelerate.aliyuncs.com/${file.name}`,
        fileName: formData.name,
        fileType: applicationSuffix,
        commentId: "",
        createComment: "",
        commentsTotal: 0,
        commentHistory: false,
        explainText: "",
        fileInfo: {
          size: formData.size,
          type: formData.type,
          name: file && file.name,
          copyimg: !!formData.copysrc
        },
        type: "filesSequence",
        style: {
          top:
            Math.floor((topRange < 0 ? 0 : topRange) / this.homeViewScale) +
            "px",
          left:
            Math.floor((leftRange < 0 ? 0 : leftRange) / this.homeViewScale) +
            "px",
          width: "650px",
          height: "120px",
          zIndex: 10 + this.fileProgressInfo.itemIndex
        },
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0
      };
      switch (fileType) {
        case "image":
          if (applicationSuffix === "svg") {
            fileType = "svg";
            break;
          }
          nodeInfo.id = creatElementId("image");
          nodeInfo.fileType = "image";
          nodeInfo.type = "imagesSequence";
          nodeInfo.commentsTotal = {};
          let imgDom = document.createElement("img");
          imgDom.src = nodeInfo.src;
          imgDom.onload = () => {
            let maxHeight = this.paperViewZoomInfo.height - 120;
            let maxWidth = this.paperViewZoomInfo.width;
            let imgScale = 1;
            if (imgDom.width >= imgDom.height) {
              if (imgDom.width > maxWidth) {
                imgScale = maxWidth / imgDom.width;
              }
            } else {
              if (imgDom.height > maxHeight) {
                imgScale = maxHeight / imgDom.height;
              }
            }
            nodeInfo.style.width = imgDom.width * imgScale + "px";
            nodeInfo.style.height = imgDom.height * imgScale + "px";
            this.$set(
              this.paperViewAll[nodeInfo.page_index][nodeInfo.type],
              nodeInfo.id,
              nodeInfo
            );
            this.updataPaperInfo(nodeInfo);
          };
          nodeInfo.style.width = null;
          nodeInfo.style.height = null;
          delete nodeInfo.commentId;
          break;
        case "audio":
        case "video":
          nodeInfo.fileType = fileType;
          break;
      }
      if (fileType !== "image") {
        this.$set(
          this.paperViewAll[nodeInfo.page_index][nodeInfo.type],
          nodeInfo.id,
          nodeInfo
        );
        this.updataPaperInfo(nodeInfo);
      }
      return nodeInfo;
    },
    // 按键绑定
    keysBind() {
      document.addEventListener("keydown", this.keyActiveEvent);
      document.addEventListener("copy", this.domCopyBind);
      document.addEventListener("paste", this.domPasteBind);
    },
    // 移除按键绑定
    keysRemoveEvent() {
      document.removeEventListener("keydown", this.keyActiveEvent);
      document.removeEventListener("copy", this.domCopyBind);
      document.removeEventListener("paste", this.domPasteBind);
    },
    // 快捷键响应
    keyActiveEvent(keyboardEvent) {
      // console.log(keyboardEvent);
      let key = keyboardEvent.keyCode;
      this.cursorComment = false;

      let ctrlKey = keyboardEvent.ctrlKey || keyboardEvent.metaKey;
      let shiftKey = keyboardEvent.shiftKey;

      // 有元素获取焦点时不执行操作
      if (!(document.activeElement instanceof HTMLBodyElement)) {
        return;
      }
      if (this.paperOperateMode !== 2) {
        if (this.paperOperateMode === 3 && key == 56) {
          this.cursorComment = true;
          this.$SetEditToolbarType(key - 48);
        }
        return;
      }
      if (key > 48 && key < 58) {
        switch (key) {
          case 53:
            keyboardEvent.preventDefault();
            this.$SetEditToolbarType(key - 48);
            break;
          case 54:
            this.showFormFileSelect();
            break;
          case 56:
            // 添加评论，更换鼠标指针样式
            this.cursorComment = true;
            this.$SetEditToolbarType(key - 48);
            break;
          case 57:
            this.uploadCanvasWrapper();
            break;
          default:
            this.$SetEditToolbarType(key - 48);
            break;
        }
        return false;
      }
      switch (key) {
        // 删除节点
        case 8:
        case 46:
          this.delDomElement();
          break;
        case 65: // 全选当前页节点
          if (ctrlKey) {
            this.pageNodeSelectAll();
          }
          break;
        case 80: // 工具栏选中便签选项
          this.$SetEditToolbarType(4);
          break;
        case 83: // 阻止ctrl+s 保存页面
          if (ctrlKey) {
            keyboardEvent.preventDefault();
          }
          break;
        case 84: // 工具栏选中文本选项
          this.$SetEditToolbarType(3);
          break;
        case 90: // 撤销与恢复
          if (ctrlKey && !shiftKey) {
            this.operationRevoke("revoke");
          } else if (ctrlKey && shiftKey) {
            this.operationRevoke("recovery");
          }
          break;
        case 187: // 放大页面
          if (ctrlKey) {
            keyboardEvent.preventDefault();
            this.$refs.PaperViewZoom.viewZoomAdd();
          }
          break;
        case 189: // 缩放页面
          if (ctrlKey) {
            keyboardEvent.preventDefault();
            this.$refs.PaperViewZoom.viewZoomMinus();
          }
          break;
      }
    },
    // 扩展菜单事件响应
    xppMenuBack(target) {
      let { id, type, page_index } = this.paperActiveDom;
      let dd = this.paperActiveDom;
      switch (target.dataset.type) {
        case "lock":
          this.xppElementLock(dd);
          break;
        case "delete":
          this.delDomElement();
          break;
        case "new_paper":
          let {
            target: paperPage,
            offsetX,
            offsetY
          } = this.paperMousemoveEvent;
          if (!paperPage.classList.contains("xpp-paper-dom-box")) return;
          this.callPaperPageEvent(paperPage.id, "paperCreatNode", {
            offsetX,
            offsetY
          });
          break;
        case "open_folder":
          location.href = `${location.origin}/paper/${id}`;
          break;
        case "to_top":
          this.elementViewIndexAdjust(
            document.getElementById(dd.id),
            "to_top",
            dd
          );
          break;
        case "to_bottom":
          this.elementViewIndexAdjust(
            document.getElementById(dd.id),
            "to_bottom",
            dd
          );
          break;
        case "check-bgcolor":
          if (target && target.classList.contains("xpp-context-select-item")) {
            this.$AddXppOperationRecord({
              type: "update",
              data: dd
            });
            dd.style.backgroundColor = target.dataset.value;
            this.$UpdatePaperElementDate(dd);
            this.upDataPaperNode(dd);
          }
          break;
        case "edit-tit":
          let className;
          switch (type) {
            case "hyperlinkSequence":
              className = "xpp-hyperlink-textarea";
              break;
            case "paperSequence":
              className = "xpp-paper-paper-title-bg";
              break;
          }
          document
            .getElementById(id)
            .getElementsByClassName(className)[0]
            .focus();
          break;
        case "edit-con":
          this.editText(id);
          this.$WsSendMsg({
            action: "DISABLEDELEMENT",
            data: {
              ids: [id],
              type: true
            }
          });
          break;
        case "open_file":
          this.$UpdataVuexState({
            key: "previewFileInfo",
            data: this.paperViewAll[page_index][type][id]
          });
          this.$UpdataVuexState({ key: "showFilePreview", data: true });
          break;
        case "down_load":
          let { fileInfo, fileName } = this.paperViewAll[page_index][type][id];
          if (fileInfo.copyimg) {
            this.$alert("网络转载图片，不可下载到本地");
            return;
          }
          this.$VerifyXppAllowanceCredit({
            steamConsume: fileInfo.size
          }).then(({ status, updateaxios }) => {
            if (status) {
              location.href = this.aliyunDownFiles(fileInfo.name, {
                "content-disposition": `attachment;filename=${fileName}`
              });
              updateaxios();
            }
          });
          break;
        case "comment":
          this.commentInfoShow();
          break;
        case "backup":
          this.$UpdateEditHistoryList({ page: 1, data: dd });
          this.$DiaLoginFoUpDate({
            key: "xppEditHistoryLogState",
            value: true
          });
          break;
        case "copy":
          this.xppElementDataCopy();
          break;
        case "paste":
          this.xppElementDataPaste();
          break;
        case "duplicate":
          this.xppElementDataCopy();
          this.xppElementDataPaste();
          break;
        case "check-image":
          this.canvasthumbUpdata(dd);
          break;
        case "align":
          this.xppElementalign(dd, target.dataset.value);
          break;
        case "revoke":
          this.operationRevoke("revoke");
          break;
        case "recovery":
          this.operationRevoke("recovery");
          break;
        case "open_image":
          this.$XppPicturePreviewShow({
            type: true,
            data: dd
          });
          break;
        default:
          console.log(target.dataset.type);
          break;
      }
    },
    // 记录选中节点
    activityNodeRecord(target) {
      // 选中节点记录
      let classLists = target.classList;
      let activeDom = {};
      let paperPage = this.lastEventCoordinate.paperPage;
      if (classLists.contains("xpp-paper-dom-box")) {
        this.$SetPaperActiveDom();
        this.removeSelectActiveNode();
        return {
          type: "elementCheckBox",
          elements: paperPage
        };
      }
      activeDom.elements = parentLocation(target, ".xpp-paper-obj");
      if (!activeDom.elements) {
        this.$SetPaperActiveDom();
        return {};
      }
      if (classLists.contains("ui-resizable-handle")) {
        activeDom.type = "resizable";
        this.removeSelectActiveNode();
        this.selectedNodeArr = [activeDom.elements];
      } else {
        activeDom.type = "elementNode";
        if (
          activeDom.elements.classList.contains("xpp-select-active") &&
          this.paperActiveDom instanceof Array
        ) {
          return activeDom;
        } else {
          this.removeSelectActiveNode();
          this.selectedNodeArr = [activeDom.elements];
        }
      }
      if (activeDom.elements.dataset.lock) {
        this.$SetPaperActiveDom();
        return {
          type: "elementCheckBox",
          elements: paperPage
        };
      } else {
        activeDom.elements.classList.add("xpp-select-active");
        this.$SetPaperActiveDom({
          id: activeDom.elements.id,
          type: activeDom.elements.dataset.type,
          index: activeDom.elements.dataset.index
        });
      }
      return activeDom;
    },
    // 临界位置计算
    paperNodePosition(event, element, oldPageID, eventType) {
      let pageIndex = element.dataset.index;
      let pageInfo = this.paperViewAll[pageIndex];

      if (eventType === "resizable") {
        let item = pageInfo[element.dataset.type][element.id];
        this.$AddXppOperationRecord({
          type: "update",
          data: item
        });
        item.style.width = element.style.width;
        item.style.height = element.style.height;
        item.style.top = element.style.top;
        item.style.left = element.style.left;
        return this.updataPaperInfo(item);
      }
      this.selectLoopStatus = false; // 事件阀门关闭
      let { target } = event;
      let targetContainer = parentLocation(target, ".xpp-paper-dom-box");
      if (!targetContainer) {
        targetContainer = parentLocation(element, ".xpp-paper-dom-box");
      }
      let newPageID = targetContainer.id;
      let elementChangeOff = newPageID !== oldPageID;
      let elementAll = [];
      let elementHistory = [];
      for (let i = 0; i < this.selectedNodeArr.length; i++) {
        let elNode = this.selectedNodeArr[i];
        let type = elNode.dataset.type;
        let itemInfo = pageInfo[type][elNode.id];
        elementHistory.push(JSON.parse(JSON.stringify(itemInfo)));
        let { offsetTop, offsetLeft, clientWidth, clientHeight } = elNode;
        let maxTop = this.paperViewZoomInfo.height - clientHeight;
        let maxleft = this.paperViewZoomInfo.width - clientWidth;
        let translate = null;
        elNode.style.pointerEvents = null;
        if (elNode.style.transform) {
          translate = elNode.style.transform
            .match(/-?\d+px,\s?-?\d+px/)[0]
            .split(/,\s?/)
            .map(item => item.replace("px", "") - 0);
          offsetLeft = offsetLeft + translate[0];
          offsetTop = offsetTop + translate[1];
          elNode.style.transform = null;
        }
        if (elementChangeOff && targetContainer) {
          let pageViewHeight = this.paperViewZoomInfo.height;
          if (offsetTop < 0) {
            offsetTop =
              pageViewHeight -
              ((pageViewHeight - offsetTop) % (pageViewHeight + 40));
          } else {
            let deviation = Math.max(Math.floor(offsetTop / pageViewHeight), 1);
            offsetTop = (offsetTop % pageViewHeight) - deviation * 40;
          }
        } else {
          offsetTop = Math.min(offsetTop, maxTop);
          if (offsetTop < 0) {
            offsetTop = 0;
          }
        }

        if (offsetLeft > maxleft) {
          offsetLeft = maxleft;
        }
        if (offsetLeft < 0) {
          offsetLeft = 0;
        }
        itemInfo.style.top = elNode.style.top = offsetTop + "px";
        itemInfo.style.left = elNode.style.left = offsetLeft + "px";
        itemInfo.page_index = targetContainer.dataset.index;
        itemInfo.page_id = this.paperViewAll[itemInfo.page_index].page_id;
        elementAll.push(itemInfo);
      }
      if (elementChangeOff) {
        this.delDomElement();
        this.creatPaperElement(
          elementAll.length > 1 ? elementAll : elementAll[0]
        );
      } else {
        this.updataPaperInfo(
          elementAll.length > 1 ? elementAll : elementAll[0]
        );
      }
      this.$AddXppOperationRecord({
        type: "drag",
        data: elementHistory
      });
      this.selectLoopStatus = true; // 后续事件放行
    },
    // 保存当前激活page下节点坐标集合
    updataGrid({ id, children }) {
      let pageChildren = {
        targetId: id,
        x: [this.paperPageMiddleGrid.x],
        y: [this.paperPageMiddleGrid.y]
      };

      for (let i = 0; i < children.length; i++) {
        let {
          offsetTop,
          offsetLeft,
          offsetHeight,
          offsetWidth,
          classList
        } = children[i];
        if (!classList.contains("xpp-select-active")) {
          // 过滤自身
          pageChildren.x.push(offsetLeft, offsetLeft + offsetWidth);
          pageChildren.y.push(offsetTop, offsetTop + offsetHeight);
        }
      }
      this.$SetPaperPageNodeGrid(pageChildren);
    },
    // 节点移入其他paper
    async checkBatchMoveTarget(target) {
      let { info: firstpageId } = await this.$axios.get(
        "/dis/p/getfirstpageid",
        {
          params: {
            parentId: target.id
          }
        }
      );
      let message = {
        action: "BATCHMOVE",
        dataarray: [],
        target: [
          {
            _id: firstpageId
          }
        ]
      };
      for (let item of this.selectedNodeArr) {
        item.style.zIndex = 201;
        let itemanimate = item.animate(
          [
            {
              transform: item.style.transform
            },
            {
              transform: item.style.transform.replace("(0.6)", "(0)")
            }
          ],
          {
            fill: "forwards",
            easing: "ease",
            duration: 300
          }
        );
        itemanimate.onfinish = () => {
          itemanimate.cancel();
          item.style.opacity = 0;
          item.style.transform = item.style.transform.replace("(0.6)", "(0)");
        };
        message.dataarray.push(this.updataFormat(this.forTargetToDate(item)));
      }
      setTimeout(() => {
        this.$WsSendMsg(message);
        this.$ElementCheckBatchMoveTarget(message.dataarray);
      }, 400);
    },
    // paper内相关事件绑定
    mouseIncidentMonitor() {
      if (this.paperOperateMode !== 2) {
        return this.paperCommentEventInit();
      }
      let paperContainer = this.$refs.paperContainer;
      let oldPageID = null;
      let counter = 0; // 鼠标移动距离
      let targetNode = {}; // 指针选中节点信息
      let eventClientX, eventClientY, eventPageX, eventPageY;
      let scrollHeight = null;
      let targetEventNode = null; // 目标节点
      let autoScrollLoop = null; // 鼠标移除页面后loop倒计时
      let elementCheckBox = this.$refs.elementCheckBox; // 拖选框
      let pageChildList = null; // 开始托选时page内节点列表
      let selectElementTimeOff = null; // 拖选框确认碰撞节点 节流倒计时
      let selectedNodeFunOff = false; // 是否执行过 激活托选选中节点事件
      // 鼠标移动方向
      let eventOffsetLog = { x: null, y: null };

      // 缩放节点时辅助线计算
      let nodeZoomGridCompute = (target, direction) => {
        let gridOffset = {};
        let { offsetLeft, offsetTop, offsetWidth, offsetHeight } = target;
        let offsetRight = offsetLeft + offsetWidth;
        let offsetBottom = offsetTop + offsetHeight;
        let { x: axisX, y: axisY } = this.paperPageNodeGrid;
        for (let i = 0; i < axisX.length; i++) {
          let x = axisX[i];
          let y = axisY[i];
          let differenceX = x - offsetLeft;
          let differenceX_r = x - offsetRight;
          let differenceY = y - offsetTop;
          let differenceY_b = y - offsetBottom;
          let type = null;
          switch (direction) {
            case "nw":
            case "w":
            case "sw":
              if (
                Math.abs(differenceX) <= this.correctionScale &&
                !gridOffset.x
              ) {
                type = "x";
                offsetLeft = x;
                offsetWidth -= differenceX;
              }
              break;
            case "ne":
            case "e":
            case "se":
              if (
                Math.abs(differenceX_r) <= this.correctionScale &&
                !gridOffset.x
              ) {
                type = "x";
                offsetRight = x;
                offsetWidth += differenceX_r;
              }
              break;
          }
          switch (direction) {
            case "nw":
            case "ne":
              if (
                Math.abs(differenceY) <= this.correctionScale &&
                !gridOffset.y
              ) {
                type = "y";
                offsetTop = y;
                offsetHeight -= differenceY;
              }
              break;
            case "sw":
            case "s":
            case "se":
              if (
                Math.abs(differenceY_b) <= this.correctionScale &&
                !gridOffset.y
              ) {
                type = "y";
                offsetBottom = y;
                offsetHeight += differenceY_b;
              }
              break;
          }

          switch (x) {
            case offsetLeft:
            case offsetRight:
              if (type === "x") {
                gridOffset.x = {
                  type: "x",
                  class: {
                    "grid-path-x": true,
                    "grid-middle-path": this.paperPageMiddleGrid.x === x
                  },
                  style: {
                    left: `${x}px`
                  }
                };
                break;
              }
          }
          switch (y) {
            case offsetTop:
            case offsetBottom:
              if (type === "y") {
                gridOffset.y = {
                  type: "y",
                  class: {
                    "grid-middle-path": this.paperPageMiddleGrid.y === y
                  },
                  style: { top: `${y}px` }
                };
              }
              break;
          }
        }
        target.style.left = offsetLeft + "px";
        target.style.top = offsetTop + "px";
        target.style.width = offsetWidth + "px";
        target.style.height = offsetHeight + "px";
        return { off: !!gridOffset.x || !!gridOffset.y, gridOffset };
      };
      // 节点移动时辅助线计算
      let pageGridCompute = (xx, yy, event) => {
        let gridOffset = {};
        let {
          offsetLeft,
          offsetTop,
          offsetHeight,
          offsetWidth
        } = targetEventNode;
        let elOffsetLeft = offsetLeft + xx;
        let elOffsetTop = offsetTop + yy;
        let elOffsetLeftRight = elOffsetLeft + offsetWidth;
        let elOffsetTopBottom = elOffsetTop + offsetHeight;
        let { x: axisX, y: axisY } = this.paperPageNodeGrid;
        let off = false;

        let eventLeftMove = eventOffsetLog.x >= event.pageX;
        let eventTopMove = eventOffsetLog.y >= event.pageY;

        for (let i = 0; i < axisX.length; i++) {
          let x = axisX[i];
          let y = axisY[i];
          let differenceX = x - elOffsetLeft;
          let differenceX_r = x - elOffsetLeftRight;
          let differenceY = y - elOffsetTop;
          let differenceY_b = y - elOffsetTopBottom;
          switch (eventLeftMove) {
            case true:
              if (
                Math.abs(differenceX) <= this.correctionScale &&
                !gridOffset.x
              ) {
                elOffsetLeft = x;
                xx += differenceX;
              }
              break;
            case false:
              if (
                Math.abs(differenceX_r) <= this.correctionScale &&
                !gridOffset.x
              ) {
                elOffsetLeftRight = x;
                xx += differenceX_r;
              }
              break;
          }

          switch (eventTopMove) {
            case true:
              if (
                Math.abs(differenceY) <= this.correctionScale &&
                !gridOffset.y
              ) {
                elOffsetTop = y;
                yy += differenceY;
              }
              break;
            case false:
              if (
                Math.abs(differenceY_b) <= this.correctionScale &&
                !gridOffset.y
              ) {
                elOffsetTopBottom = y;
                yy += differenceY_b;
              }
              break;
          }

          switch (x) {
            case elOffsetLeft:
            case elOffsetLeftRight:
              gridOffset.x = {
                type: "x",
                class: {
                  "grid-path-x": true,
                  "grid-middle-path": this.paperPageMiddleGrid.x === x
                },
                style: {
                  left: `${x}px`
                }
              };
              break;
          }

          switch (y) {
            case elOffsetTop:
            case elOffsetTopBottom:
              gridOffset.y = {
                type: "y",
                class: {
                  "grid-middle-path": this.paperPageMiddleGrid.y === y
                },
                style: { top: `${y}px` }
              };
              break;
          }
        }
        off = !!gridOffset.x || !!gridOffset.y;
        return { translateX: xx, translateY: yy, off, gridOffset };
      };
      // 缩放节点
      let zoomEvent = (
        event,
        target,
        direction,
        { clientX, clientY, clientWidth, clientHeight, offsetTop, offsetLeft }
      ) => {
        let domRect = target.parentElement.getBoundingClientRect();
        if (
          domRect.top > event.clientY ||
          domRect.top + domRect.height < event.clientY
        ) {
          return;
        }
        if (
          domRect.left > event.clientX ||
          domRect.left + domRect.width < event.clientX
        ) {
          return;
        }
        let zoomconvert = clientWidth / clientHeight;
        if (target.dataset.type === "imagesSequence") {
          let addh, addw;
          switch (direction) {
            case "nw":
              addh = Math.floor(
                clientHeight - (event.clientY - clientY) / this.homeViewScale
              );
              addw = addh * zoomconvert;
              target.style.height = addh + "px";
              target.style.width = addw + "px";
              if (clientHeight !== addh && addh >= 10) {
                target.style.top =
                  Math.floor(
                    offsetTop + (event.clientY - clientY) / this.homeViewScale
                  ) + "px";
              }

              if (clientWidth !== addw && addw >= 10) {
                target.style.left = offsetLeft + (clientWidth - addw) + "px";
              }
              break;
            case "ne":
              addh = Math.floor(
                clientHeight - (event.clientY - clientY) / this.homeViewScale
              );
              addw = addh * zoomconvert;
              target.style.width = addh * zoomconvert + "px";
              target.style.height = addh + "px";

              if (clientHeight !== addh && addh >= 10) {
                target.style.top =
                  Math.floor(
                    offsetTop + (event.clientY - clientY) / this.homeViewScale
                  ) + "px";
              }
              break;
            case "sw":
              addh = Math.floor(
                clientHeight + (event.clientY - clientY) / this.homeViewScale
              );
              addw = addh * zoomconvert;

              target.style.height = addh + "px";
              target.style.width = addw + "px";
              if (clientWidth !== addw && addw >= 10) {
                target.style.left = offsetLeft + (clientWidth - addw) + "px";
              }
              break;
            case "se":
              addh = Math.floor(
                clientHeight + (event.clientY - clientY) / this.homeViewScale
              );
              target.style.height = addh + "px";
              target.style.width = addh * zoomconvert + "px";
              break;
          }
        } else {
          switch (direction) {
            case "nw":
              target.style.width =
                Math.floor(
                  clientWidth + (clientX - event.clientX) / this.homeViewScale
                ) + "px";
              target.style.height =
                Math.floor(
                  clientHeight - (event.clientY - clientY) / this.homeViewScale
                ) + "px";
              if (clientHeight !== target.clientHeight) {
                target.style.top =
                  Math.floor(
                    offsetTop + (event.clientY - clientY) / this.homeViewScale
                  ) + "px";
              }
              if (clientWidth !== target.clientWidth) {
                target.style.left =
                  Math.floor(
                    offsetLeft + (event.clientX - clientX) / this.homeViewScale
                  ) + "px";
              }
              break;
            case "ne":
              target.style.width =
                Math.floor(
                  clientWidth + (event.clientX - clientX) / this.homeViewScale
                ) + "px";
              target.style.height =
                Math.floor(
                  clientHeight - (event.clientY - clientY) / this.homeViewScale
                ) + "px";
              if (clientHeight !== target.clientHeight) {
                target.style.top =
                  Math.floor(
                    offsetTop + (event.clientY - clientY) / this.homeViewScale
                  ) + "px";
              }
              break;
            case "sw":
              target.style.width =
                Math.floor(
                  clientWidth + (clientX - event.clientX) / this.homeViewScale
                ) + "px";
              target.style.height =
                Math.floor(
                  clientHeight + (event.clientY - clientY) / this.homeViewScale
                ) + "px";
              if (clientWidth !== target.clientWidth) {
                target.style.left =
                  Math.floor(
                    offsetLeft + (event.clientX - clientX) / this.homeViewScale
                  ) + "px";
              }
              break;
            case "se":
              target.style.height =
                Math.floor(
                  clientHeight + (event.clientY - clientY) / this.homeViewScale
                ) + "px";
              target.style.width =
                Math.floor(
                  clientWidth + (event.clientX - clientX) / this.homeViewScale
                ) + "px";
              break;
            case "w":
              target.style.width =
                Math.floor(
                  clientWidth + (clientX - event.clientX) / this.homeViewScale
                ) + "px";
              if (clientWidth === target.clientWidth) {
                return;
              }
              target.style.left =
                Math.floor(
                  offsetLeft + (event.clientX - clientX) / this.homeViewScale
                ) + "px";
              break;
            case "s":
              target.style.height =
                Math.floor(
                  clientHeight + (event.clientY - clientY) / this.homeViewScale
                ) + "px";
              break;
            case "e":
              target.style.width =
                Math.floor(
                  clientWidth + (event.clientX - clientX) / this.homeViewScale
                ) + "px";
              break;
          }
        }

        let { off, gridOffset } = nodeZoomGridCompute(target, direction);
        this.$SetPaperGridPathInfo(off ? gridOffset : null);
      };

      // 拖动节点
      let prevHoverElement = null;
      let hoverPaperPrevOffset = [];
      let elementDrag = (event, screenClientHeight, step = 10) => {
        counter++;
        if (counter % 2 === 0) {
          if (screenClientHeight - event.pageY < 50) {
            if (paperContainer.scrollTop + step < scrollHeight) {
              paperContainer.scrollTo(
                paperContainer.scrollLeft,
                Math.min(paperContainer.scrollTop + step, scrollHeight)
              );
              eventClientY -= step;
            }
          }
          if (event.pageY < 76) {
            if (paperContainer.scrollTop > step) {
              paperContainer.scrollTo(
                paperContainer.scrollLeft,
                Math.max(paperContainer.scrollTop - step, 0)
              );
              eventClientY += step;
            }
          }
        }
        let xx = Math.floor(
          (event.clientX - eventClientX) / this.homeViewScale
        );
        let yy = Math.floor(
          (event.clientY - eventClientY) / this.homeViewScale
        );
        if (
          event.target.classList.contains("xpp-paper-paper-box") &&
          event.target.id !== targetEventNode.id
        ) {
          this.$SetPaperGridPathInfo(null);
          if (prevHoverElement === event.target) {
            for (let item of this.selectedNodeArr) {
              item.style.transform = `translate(${xx -
                (item.offsetLeft - targetEventNode.offsetLeft)}px, ${yy -
                (item.offsetTop - targetEventNode.offsetTop)}px) scale(0.6)`;
            }
          } else {
            for (let item of this.selectedNodeArr) {
              let { top, left, width, height, transform } = item.style;
              hoverPaperPrevOffset.push({
                top,
                left,
                width,
                height,
                transform
              });
              let animateTransform = null;
              let x = xx - (item.offsetLeft - targetEventNode.offsetLeft);
              let y = yy - (item.offsetTop - targetEventNode.offsetTop);
              animateTransform = `translate(${x}px, ${y}px) scale(0.6)`;
              let animate = item.animate(
                [
                  { transform },
                  {
                    transform: animateTransform
                  }
                ],
                {
                  fill: "forwards",
                  easing: "ease",
                  duration: 300
                }
              );
              animate.onfinish = () => {
                item.style.transform = animateTransform;
                animate.cancel();
              };
            }
            prevHoverElement = event.target;
          }
        } else {
          let { off, gridOffset, translateX, translateY } = pageGridCompute(
            xx,
            yy,
            event
          );
          this.$SetPaperGridPathInfo(off ? gridOffset : null);
          prevHoverElement = event.target;
          for (let i = 0; i < this.selectedNodeArr.length; i++) {
            let elnode = this.selectedNodeArr[i];
            elnode.style.transform = `translate(${translateX}px, ${translateY}px)`;
          }
        }
      };

      let elementSelectTimeOut = ({ x, y, w, h }) => {
        clearTimeout(selectElementTimeOff);
        if (!pageChildList) {
          return;
        }
        selectElementTimeOff = setTimeout(() => {
          this.selectedNodeArr = this.elementCollisionDetection(
            { x, y, w, h },
            pageChildList,
            (item, off) => {
              if (off) {
                item.classList.add("ui-selecting");
              } else {
                item.classList.remove("ui-selecting");
              }
            }
          );

          if (selectedNodeFunOff) {
            // 已经松开鼠标，但是因为延迟未判断出选中节点
            this.selectedNodeFun();
          }
        }, 80);
      };
      let elementDragCheckBox = event => {
        counter++;
        let { pageX, pageY } = event;
        let y = eventPageY - pageY,
          x = eventPageX - pageX,
          w = Math.abs(x),
          h = Math.abs(y);
        elementCheckBox.style.opacity = 1;
        if (y > 0) {
          y = pageY;
          elementCheckBox.style.top = y + "px";
        }
        if (x > 0) {
          x = pageX;
          elementCheckBox.style.left = x + "px";
        }
        elementCheckBox.style.width = w + "px";
        elementCheckBox.style.height = h + "px";
        elementSelectTimeOut({
          x: x < 0 ? eventPageX : x,
          y: y < 0 ? eventPageY : y,
          w,
          h
        });
      };

      paperContainer.onmouseout = event => {
        if (!this.paperElementDropStart) {
          clearInterval(autoScrollLoop);
          return;
        }
        if (this.activeRemovePath) return; // svg橡皮擦激活
        if (
          event.pageY <= 56 ||
          event.pageY >= document.documentElement.clientHeight
        ) {
          autoScrollLoop = setInterval(() => {
            elementDrag(event, document.documentElement.clientHeight, 20);
          }, 0);
        }
      };
      paperContainer.onmousedown = ev => {
        console.time("mousedown");
        // 非主要按键点击时阻断事件继续
        if (ev.detail !== 1 || ev.button !== 0 || ev.buttons !== 1) {
          return;
        }
        if (this.activeRemovePath) return; // svg橡皮擦激活
        let { button, target, clientX, clientY, pageX, pageY } = ev;
        if (target.tagName === "LI") {
          let nullEdit = document.activeElement instanceof HTMLBodyElement;
          let off = target.offsetParent.classList.contains("ql-editor");
          let parent = target.offsetParent;
          if (nullEdit && off) {
            this.$QuillCheckListChange({ target, parent });
            return;
          }
        }
        let paperPage = parentLocation(target, ".xpp-paper-dom-box");
        this.$UpdateLastEventCoordinate({ event: ev, paperPage });

        // 移除评论活跃状态
        if (this.cursorComment || target.dataset.event === "addComment") {
          this.cursorComment = false;
          this.$SetEditToolbarType(1);
          return this.commentInfoShow();
        }
        this.hideModified();
        switch (target.dataset.event) {
          case "openlink":
            let url = target.innerText;
            if (url.indexOf("http") < 0) {
              url = "https://" + url;
            }
            return window.open(url, "_blank", "noopener");
        }
        // 仅点击鼠标左键时事件生效
        if (button !== 0) {
          return;
        }
        if (paperPage) {
          this.$DiaLoginFoUpDate({ key: "xppCommentShow", value: false });
          this.$SaveElementCommentInfo();
        }
        eventClientX = clientX;
        eventClientY = clientY;
        eventPageX = pageX;
        eventPageY = pageY;
        counter = 0;
        // 如果点击时处于拖动状态，说明用户在窗口外松开了按键
        if (this.paperElementDropStart) {
          return paperContainer.onmouseup(event);
        }
        if (!(document.activeElement instanceof HTMLBodyElement)) {
          return;
        }
        targetNode = this.activityNodeRecord(target);
        eventOffsetLog.x = pageX;
        eventOffsetLog.y = pageY;
        targetEventNode = targetNode.elements;
        if (!targetEventNode) return;
        targetEventNode.style.transition = null;
        switch (targetNode.type) {
          case "resizable":
            ev.preventDefault();
            ev.stopPropagation();
            let {
              clientWidth,
              clientHeight,
              offsetTop,
              offsetLeft
            } = targetEventNode;
            // 重置坐标集合
            this.notiPaperElementDisabled([targetEventNode.id], true);
            paperContainer.onmousemove = event => {
              counter++;
              if (!this.paperElementDropStart) {
                // 拖动状态激活
                this.$UpdataVuexState({
                  key: "paperElementDropStart",
                  data: oldPageID
                });
                // 重置坐标集合
                this.updataGrid(targetEventNode.parentElement);
              }
              if (counter % 2) {
                zoomEvent(event, targetEventNode, target.dataset.direction, {
                  clientX,
                  clientY,
                  clientWidth,
                  clientHeight,
                  offsetTop,
                  offsetLeft
                });
              }
              eventOffsetLog.x = event.pageX;
              eventOffsetLog.y = event.pageY;
            };
            break;
          case "elementNode":
            if (!this.selectLoopStatus) {
              return;
            }
            this.notiPaperElementDisabled(
              this.selectedNodeArr.map(item => item.id),
              true
            );
            oldPageID = targetEventNode.parentElement.id;
            scrollHeight =
              paperContainer.scrollHeight - paperContainer.clientHeight;
            paperContainer.onmousemove = event => {
              event.preventDefault();
              event.stopPropagation();
              if (!this.paperElementDropStart) {
                // 拖动状态激活
                this.$UpdataVuexState({
                  key: "paperElementDropStart",
                  data: oldPageID
                });
                // 重置坐标集合
                this.updataGrid(targetEventNode.parentElement);
              }
              clearInterval(autoScrollLoop);
              elementDrag(event, document.documentElement.clientHeight);
              eventOffsetLog.x = event.pageX;
              eventOffsetLog.y = event.pageY;
            };
            break;
          case "elementCheckBox":
            selectedNodeFunOff = false;
            this.selectedNodeArr = [];
            elementCheckBox.style.top = Math.floor(eventPageY) + "px";
            elementCheckBox.style.left = Math.floor(eventPageX) + "px";
            pageChildList = targetEventNode.children;
            paperContainer.onmousemove = elementDragCheckBox;
            break;
        }
        console.timeEnd("mousedown");
      };

      paperContainer.onmouseup = event => {
        console.time("mouseup");
        // 可能为双击时阻断事件继续
        if (event.detail !== 1) {
          return;
        }
        if (this.activeRemovePath) return; // svg橡皮擦激活
        clearInterval(autoScrollLoop);
        eventClientY = eventClientX = null;
        paperContainer.onmousemove = null;
        elementCheckBox.style = null;
        // 拖动状态关闭
        this.$UpdataVuexState({
          key: "paperElementDropStart",
          data: false
        });
        this.$SetPaperGridPathInfo(); // 清空辅助线信息
        // 鼠标移动次数大于5时更新鼠标位置信息
        if (counter > 5) {
          this.$UpdateLastEventCoordinate({
            event,
            paperPage: parentLocation(event.target, ".xpp-paper-dom-box")
          });
        }
        switch (targetNode.type) {
          case "elementCheckBox":
            selectedNodeFunOff = true;
            this.selectedNodeFun();
            break;
          case "resizable":
          case "elementNode":
            targetEventNode.style.pointerEvents = null;

            if (counter > 5) {
              if (
                event.target.classList.contains("xpp-paper-paper-box") &&
                !event.target.classList.contains("xpp-select-active") &&
                targetNode.type === "elementNode"
              ) {
                return this.checkBatchMoveTarget(event.target);
              }
              counter = 0;
              this.paperNodePosition(
                event,
                targetEventNode,
                oldPageID,
                targetNode.type
              );
            } else {
              this.removeSelectActiveNode();
              targetEventNode.classList.add("xpp-select-active");
              this.selectedNodeArr = [targetEventNode];
            }
            break;
        }
        this.notiPaperElementDisabled(
          this.selectedNodeArr.map(item => item.id),
          this.selectedNodeArr.length > 0
        );
        console.timeEnd("mouseup");
      };
    },
    // 仅可评论时对事件的绑定
    paperCommentEventInit() {
      this.$refs.paperContainer.onmousedown = ev => {
        let paperPage = parentLocation(ev.target, ".xpp-paper-dom-box");
        this.$UpdateLastEventCoordinate({ event: ev, paperPage });
        // 移除评论活跃状态
        if (this.cursorComment || ev.target.dataset.event === "addComment") {
          this.cursorComment = false;
          this.$SetEditToolbarType(1);
          return this.commentInfoShow();
        }
        if (paperPage) {
          this.$DiaLoginFoUpDate({ key: "xppCommentShow", value: false });
          this.$SaveElementCommentInfo();
        }
      };
    },
    // 隐藏节点更新信息
    hideModified() {
      this.prevMousemoveTg = null;
      Object.assign(this.modifiedInfo, {
        show: false,
        style: null,
        targetId: null,
        id: "",
        time: 0,
        headImg: ""
      });
    },
    // 显示节点更新信息
    modifiedStateVail(dom) {
      // 如果鼠标指针不在节点上 - 隐藏节点更新信息
      // 有元素获取焦点时不执行操作
      // 用户开始拖动节点时隐藏节点更新信息
      if (
        !dom ||
        !(document.activeElement instanceof HTMLBodyElement) ||
        this.paperElementDropStart
      ) {
        return this.hideModified();
      }

      if (this.prevMousemoveTg === dom) return;
      this.prevMousemoveTg = dom;
      let offset = dom.getBoundingClientRect();
      let data = this.forTargetToDate(dom);
      if (!data || !data.updataInfo) {
        return this.hideModified();
      }
      let { userId: id, headImg, time, nickName } = data.updataInfo;
      this.$set(
        this,
        "modifiedInfo",
        Object.assign(this.modifiedInfo, {
          style: {
            top: offset.bottom + 5 + "px",
            left: offset.left + "px"
          },
          show: true,
          targetId: dom.id,
          nickName,
          headImg,
          id,
          time
        })
      );
    },
    // 清空鼠标轨迹信息
    removeTrajectoryHistory(page) {
      clearTimeout(this.trajectoryHistory.timeFb);

      this.trajectoryHistory.rect = [];
      this.trajectoryHistory.timeStamp = 0;
      this.trajectoryHistory.page = page || null;
      this.trajectoryHistory.timeFb = setTimeout(
        this.sendTrajectoryHistory,
        3000
      );
    },
    // 向其他页面发送鼠标轨迹信息
    sendTrajectoryHistory() {
      let { page, rect, timeFb } = this.trajectoryHistory;
      if (!rect.length) return;
      clearTimeout(timeFb);
      // 发送鼠标轨迹
      let prect = page.getBoundingClientRect();
      let mousemoveRecord = rect.map(item => {
        return {
          top: `${Math.floor((item.pageY - prect.top) / this.homeViewScale)}px`,
          left: `${Math.floor(
            (item.pageX - prect.left) / this.homeViewScale
          )}px`
        };
      });
      this.$WsSendMsg({
        action: "PAPERMOUSEMOVE",
        data: {
          id: page.id,
          userId: this.xppUserInfo.id,
          index: page.dataset.index,
          nickName: this.xppUserInfo.nickName,
          headImg: this.xppUserInfo.headImg,
          mousemoveRecord
        }
      });
      this.removeTrajectoryHistory();
    },
    // 记录鼠标轨迹
    trajectoryHistoriographer(paper, pageY, pageX, timeStamp) {
      if (timeStamp - this.trajectoryHistory.timeStamp < 200) return;
      this.trajectoryHistory.timeStamp = timeStamp;
      if (!paper || !this.xppUserInfo.id) {
        return;
      }
      let { page, rect } = this.trajectoryHistory;
      if (page !== paper) {
        this.removeTrajectoryHistory(paper);
      }
      this.trajectoryHistory.count++;
      rect.push({ pageY, pageX });
      if (rect.length >= 10) {
        this.sendTrajectoryHistory(paper);
      }
    },
    // 鼠标移动事件
    paperMousemove({ target, pageY, pageX, timeStamp }) {
      this.paperMousemoveEvent = arguments[0];
      let [dom, paper] = parentLocation(target, [
        ".xpp-paper-obj",
        ".xpp-paper-dom-box"
      ]);
      this.modifiedStateVail(dom);
      this.trajectoryHistoriographer(paper, pageY, pageX, timeStamp);
    },
    // 右键菜单
    paperContextMenu(event) {
      let commentBox = document.getElementById("xppCommentContent");
      if (commentBox && commentBox.contains(event.target)) {
        return;
      } else {
        event.preventDefault();
      }
      if (this.paperOperateMode === 1) {
        return;
      }
      let { target } = event;
      let paperPage = parentLocation(target, ".xpp-paper-dom-box");
      this.$UpdateLastEventCoordinate({ event, paperPage });

      // 有元素获取焦点时不执行操作
      if (!(document.activeElement instanceof HTMLBodyElement)) {
        return;
      }

      if (!paperPage) {
        return false;
      }

      if (!target.classList.contains("xpp-paper-dom-box")) {
        let selectDom = document.getElementsByClassName("xpp-select-active");
        target = parentLocation(target, ".xpp-paper-obj");
        let data = {
          id: target.id, // 元素ID
          index: target.dataset.index, // 在paper中的index
          type: target.dataset.type // 目标类型
        };
        // 选中的节点为托选结果之一
        if (selectDom.length <= 1) {
          this.$SetPaperActiveDom(data);
        } else {
          // 多选时右键，存储当前目标信息备用
          sessionStorage.setItem(
            "contextActiveDom",
            JSON.stringify(this.paperViewAll[data.index][data.type][data.id])
          );
        }
      } else {
        this.$SetPaperActiveDom();
      }

      // 位置信息及菜单展示
      this.$UpdataVuexState({
        key: "pagePositionInfo",
        data: {
          top: event.clientY,
          left: event.clientX
        }
      });
      this.$UpdataVuexState({ key: "viewMaskShow", data: true });
      this.$DiaLoginFoUpDate({ key: "paperContext", value: true });
      return false;
    },
    scrollHistoricalReview() {
      let scrollHistorical = JSON.parse(
        localStorage.getItem("PaperScrollHistorical")
      );
      if (!scrollHistorical) return;
      let [paperid, scrollleft, scrolltop] = scrollHistorical;
      if (paperid === this.paperId) {
        this.$refs.paperContainer.scrollTo(scrollleft, scrolltop);
      }
    },
    paperMousewheel(event) {
      if (event.ctrlKey || event.key === "Meta") {
        event.preventDefault();
        event.stopPropagation();
        if (event.deltaY > 0) {
          this.$refs.PaperViewZoom.viewZoomMinus();
        } else {
          this.$refs.PaperViewZoom.viewZoomAdd();
        }
      }
    },
    paperScroll({ target }) {
      // 正在拖动元素时不执行滚动检测
      if (this.paperElementDropStart) {
        return;
      }
      if (this.modifiedInfo.show) this.hideModified();

      // 滚动计算选中page
      clearTimeout(this.scrollShakeDelay);
      this.scrollShakeDelay = setTimeout(() => {
        if (!this.$refs.paperContainer) return;
        let { height, top, bottom } = this.paperPageSize;
        let index = Math.round(
          this.$refs.paperContainer.scrollTop / (height + bottom + top)
        );
        if (index >= this.paperViewAll.length) {
          index = this.paperViewAll.length - 1;
        }
        if (index <= 0) {
          index = 0;
        }
        let slides = document.getElementsByClassName("xpp-slides-main")[index];
        slides.scrollIntoView({
          behavior: "smooth"
        });
        this.$SetPaperPageInfo(index);
      });
      localStorage.setItem(
        "PaperScrollHistorical",
        // JSON.stringify([this.paperId, this.paperViewAll[index].page_id])
        JSON.stringify([this.paperId, target.scrollLeft, target.scrollTop])
      );
    },
    // 工具栏操作通知
    toolbarNotification(key) {
      this.cursorComment = false;
      switch (key) {
        case "6":
          this.showFormFileSelect();
          break;
        case "8":
          this.cursorComment = true;
          this.$SetEditToolbarType(key - 0);
          break;
        case "9":
          this.uploadCanvasWrapper();
          break;
        default:
          this.$SetEditToolbarType(key - 0);
          break;
      }
    },
    // 显示文件选择器
    showFormFileSelect() {
      let input = this.$refs.updataFiles;
      input.click();
    },
    // 文件上传
    async updataFile(formData, rectData) {
      let file;
      let { height, width, page_id } = rectData;
      if (this.paperOperateMode !== 2) {
        return;
      }
      if (!formData.copysrc) {
        let {
          status: uploadoff,
          updateaxios
        } = await this.$VerifyXppAllowanceCredit({
          storageConsume: formData.size
        });
        if (!uploadoff) {
          return;
        }
        this.fileProgress = true;
        this.fileProgressInfo.fileName = formData.name;
        file = await aliyunPut(formData, this.fileProgressInfo);
        this.fileProgressInfo.scale = 100;
        if (!file.res) {
          console.warn(file);
          this.fileUpStop();
          switch (file.name) {
            case "cancel":
              alert("进程取消");
              break;
            default:
              alert("上传失败");
              break;
          }
          return;
        }
        updateaxios(); // 更新流量信息
      }

      let topRange = rectData.top;
      let leftRange = rectData.left;
      let fileType = formData.type.replace(/\/.+/, "");

      if (this.fileProgressInfo.itemIndex) {
        topRange += Math.floor(
          Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1)
        );
        leftRange += Math.floor(
          Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1)
        );
      }
      if (topRange > height - 80) {
        topRange -= topRange - height + 80;
      }
      if (leftRange > width - 80) {
        leftRange -= leftRange - width + 80;
      }
      if (formData.type.indexOf("adobe") >= 0) {
        fileType = "";
      }

      this.creatPaperFilesNode(
        fileType,
        page_id,
        formData,
        file,
        topRange,
        leftRange
      );

      if (this.formFiles.length > 0) {
        this.fileProgressInfo.itemIndex++;
        this.fileProgressInfo.scale = 0;
        await this.updataFile(
          this.formFiles.shift(),
          this.dropEventInfo[this.fileProgressInfo.itemIndex] || arguments[1]
        );
      } else {
        setTimeout(() => {
          this.fileUpStop();
        }, 300);
      }
    },
    fileDragenter(event) {
      if (this.paperOperateMode !== 2) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      this.$UpdataVuexState({ key: "dragFileEnterOff", data: true });
    },
    fileDragleave(event) {
      if (this.paperOperateMode !== 2) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      if (!event.relatedTarget) {
        this.$UpdataVuexState({ key: "dragFileEnterOff", data: false });
      }
    },
    fileDragover(event) {
      if (this.paperOperateMode !== 2) {
        return;
      }
      event.preventDefault();
      event.stopPropagation();
      this.$refs.dropFileHelper.style.transform = `translate(${
        event.pageX
      }px, ${event.pageY - 56}px)`;
    },
    fileDrop(event, pasteFiles) {
      if (this.paperOperateMode !== 2) {
        return;
      }
      if (event.preventDefault) {
        event.preventDefault();
        event.stopPropagation();
      }
      let target = parentLocation(event.target, ".xpp-paper-dom-box");
      let files = event.dataTransfer ? event.dataTransfer.files : pasteFiles;
      if (target && files.length) {
        let { top, left, width, height } = target.getBoundingClientRect();

        this.dropEventInfo[this.fileProgressInfo.total + 1] = {
          pageX: event.pageX,
          pageY: event.pageY,
          top: event.pageY - top,
          left: event.pageX - left,
          width,
          height,
          page_id: target.id
        };
        this.fileProgressInfo.total += files.length;
        this.formFiles = [...this.formFiles, ...files];

        if (!this.fileProgress) {
          this.updataFile(this.formFiles.shift(), this.dropEventInfo[1]);
        }
      } else {
        console.warn("拖拽文件被置于选区外！");
      }
      this.$UpdataVuexState({ key: "dragFileEnterOff", data: false });
    },
    formUpdataFiles({ target }) {
      let files = target.files;
      if (!files) {
        return;
      }
      let { x: left, y: top, paperPage } = this.lastEventCoordinate;
      if (!paperPage) {
        paperPage = document.getElementById(this.paperPageInfo.page_id);
      }
      let { width, height } = paperPage.getBoundingClientRect();
      this.dropEventInfo[this.fileProgressInfo.total + 1] = {
        top,
        left,
        width,
        height,
        page_id: paperPage.id
      };
      this.fileProgressInfo.total += files.length;
      this.formFiles = [...this.formFiles, ...files];
      target.value = null;
      if (!this.fileProgress) {
        this.updataFile(this.formFiles.shift(), this.dropEventInfo[1]);
      }
    },
    fileUpStop() {
      this.fileProgress = false;
      this.fileProgressInfo.fileName = "";
      this.fileProgressInfo.itemIndex = 1;
      this.fileProgressInfo.total = 0;
      this.fileProgressInfo.scale = 0;
      aliyunStop();
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    // 生成paper缩略图&&上传
    async uploadCanvasThumb() {
      let uploadCanvasTimestamp = JSON.parse(
        localStorage.getItem(`upThumb${this.paperId}Timestamp`)
      );
      let upTimeStamp = new Date().getTime();
      if (uploadCanvasTimestamp > upTimeStamp) {
        return;
      }
      // 每天只允许一次
      localStorage.setItem(
        `upThumb${this.paperId}Timestamp`,
        upTimeStamp + 86400000
      );
      let { info } = await this.$axios.get("/mo/paper/getthumb", {
        params: {
          parentId: this.paperId,
          rootParentFlag: this.currentPapperInfo.rootParentFlag
        }
      });
      let ifream = document.createElement("iframe");
      ifream.setAttribute("frameborder", 0);
      ifream.style.cssText =
        "position: fixed;top: 0px;left: 0px;z-index: -1;opacity: 0;";
      ifream.height = 0;
      ifream.width = 0;
      try {
        window.xppprintinitinfo = {
          title: document.title,
          list: [JSON.parse(JSON.stringify(this.paperViewAll[0]))],
          type: "thumb",
          size: this.paperViewZoomInfo
        };
      } catch (err) {
        console.log(err);
      }
      let bk = file => {
        let params = {
          paperId: this.paperId,
          thump: file.url,
          thumbup: false,
          rootParentFlag: this.currentPapperInfo.rootParentFlag
        };
        this.$axios.post("/mo/paper/updatethumb", formParamsFormat(params));
      };
      window.xppprintready = async thump => {
        document.body.removeChild(ifream);
        aliyunPutThumb(
          `canvasThumb/${this.paperId}.jpeg`,
          this.dataURLtoBlob(thump),
          bk
        );
      };
      ifream.src = "/response/print.html";
      document.body.appendChild(ifream);
    },
    // 操作撤销
    operationRevoke(option) {
      let targetData = null;
      switch (option) {
        case "revoke": // 撤销
          targetData = JSON.parse(this.xppOperationRecord[0][0] || "{}");
          break;
        case "recovery": // 恢复
          targetData = JSON.parse(this.xppOperationRecord[1][0] || "{}");
          break;
        default:
          return;
      }

      if (!targetData.type) return;

      let { type, data } = targetData;
      let oldElementData = this.getPaperNodeData(data);
      switch (type) {
        case "update":
          this.$UpdatePaperElementDate(data);
          this.updataPaperInfo(data);
          break;
        case "creat":
          if (option === "revoke") {
            this.$SetPaperActiveDom(data);
            this.delDomElement("revoke");
          } else {
            oldElementData = data;
            this.creatPaperElement(data);
          }
          break;
        case "drag":
          let arr = [];
          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let dom = document.getElementById(item.id);
            let { index } = dom.dataset;
            if (index !== item.page_index + "") {
              arr.push(this.paperViewAll[index][item.type][item.id]);
            }
          }
          if (arr.length > 0) {
            this.$SetPaperActiveDom(arr);
            this.delDomElement("revoke");
            this.creatPaperElement(data.length > 1 ? data : data[0]);
          } else {
            this.$UpdatePaperElementDate(data);
            this.updataPaperInfo(data.length > 1 ? data : data[0]);
          }
          break;
        case "delete":
          if (option === "revoke") {
            oldElementData = data;
            this.creatPaperElement(data);
          } else {
            this.$SetPaperActiveDom(data);
            this.delDomElement("revoke");
          }
          break;
      }

      switch (option) {
        case "revoke": // 撤销
          this.$RevokeXppOperationRecord({ type, data: oldElementData });
          break;
        case "recovery": // 恢复
          this.$RecoveryXppOperationRecord({ type, data: oldElementData });
          break;
      }
    },
    getPaperNodeData(data) {
      let target = null;
      let datatype = data instanceof Array;
      if (datatype) {
        target = [];
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          let dom = document.getElementById(item.id);
          if (!dom) continue;
          let { index } = dom.dataset;
          target.push(this.paperViewAll[index][item.type][item.id]);
        }
      } else {
        let { id, type, page_index } = data;
        target = this.paperViewAll[page_index][type][id];
      }
      return target;
    },
    // svg工具栏通知删除线条
    backRemovePath(list) {
      this.selectedNodeArr = list;
      this.selectedNodeFun();
      this.delDomElement();
    },
    uploadCanvasWrapper() {
      let container = document.createElement("div");
      container.id = "canvasWrapperContainer";
      container.onclick = () => {
        document.body.removeChild(container);
      };
      let ifream = document.createElement("iframe");
      ifream.setAttribute("frameborder", 0);
      ifream.height = "80%";
      ifream.width = "80%";
      let fullscreen = false;
      window.xppCanvasReady = exportimg => {
        switch (exportimg) {
          case "fullscreen":
            if (fullscreen) {
              ifream.width = "80%";
              ifream.height = "80%";
              fullscreen = false;
            } else {
              ifream.width = "100%";
              ifream.height = "100%";
              fullscreen = true;
            }
            break;
          case "close":
            this.$confirm(
              "编辑还未完成。是否不保存直接退出？",
              "要关闭当前页面吗",
              {
                type: "error",
                confirmButtonText: "停留在此页面",
                cancelButtonText: "离开当前页面"
              }
            )
              .then(() => {})
              .catch(() => {
                document.body.removeChild(container);
              });
            break;
          default:
            this.formUpdataFiles({
              target: {
                files: [this.dataURLtoFile(exportimg)]
              }
            });
            document.body.removeChild(container);
            break;
        }
      };
      ifream.src = "/response/canvas.html";
      container.appendChild(ifream);
      document.body.appendChild(container);
    },
    dataURLtoFile(dataURI) {
      let type = "image/jpeg";
      let binary = atob(dataURI.split(",")[1]);
      let array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      let blod = new Blob([new Uint8Array(array)], { type });
      let files = new File(
        [blod],
        "Drawing board " + new Date().toLocaleString() + ".png",
        { type }
      );
      return files;
    },
    // 富文本编辑器选项记录
    xppToolbarConfigLog({ target }) {
      let className = target.classList.contains("ql-picker-item");
      if (className) {
        let formatDom = target.parentElement.parentElement.classList;
        let format = {};
        if (formatDom.contains("ql-font")) {
          format.font = target.dataset.value;
        } else if (formatDom.contains("ql-size")) {
          format.size = target.dataset.value;
        }
        this.$UpdataVuexState({
          key: "quilluserConfig",
          data: format,
          local: true
        });
      }
    },
    ...mapMutations([
      "$SetPaperPageInfo",
      "$SetPaperViewAll",
      "$SetPaperActiveDom",
      "$SetEditToolbarType",
      "$SetPaperGridPathInfo",
      "$SetPaperPageNodeGrid",
      "$SetPaperGroupId",
      "$DiaLoginFoUpDate",
      "$UpdataVuexState",
      "$SaveElementCommentInfo",
      "$ElementCheckBatchMoveTarget",
      "$UpdatePaperElementDate",
      "$AddXppOperationRecord",
      "$RemoveXppOperationRecord",
      "$RevokeXppOperationRecord",
      "$RecoveryXppOperationRecord",
      "$XppPicturePreviewShow",
      "$UpdateLastEventCoordinate"
    ]),
    ...mapActions([
      "$PaperWebSocket",
      "$WsSendMsg",
      "$OnlineStateChange",
      "$NavToTargetElement",
      "$GetXppAllowanceInfo",
      "$GetResourceMemberList",
      "$VerifyXppAllowanceCredit",
      "$UpdateEditHistoryList",
      "$QuillCheckListChange"
    ])
  }
};
</script>
<style lang="scss">
@import "../../../public/css/quill.snow.css";
@import "../../../public/css/paper.css";
@import "@assets/scss/toolbar.scss";

#xcanvasView {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.paper-container {
  width: 100vw;
  height: calc(100vh - #{$header-height-default});
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  background-color: #f0f1f4;
}

#paperContainer {
  flex: auto;
  height: 100%;
  overflow: auto;
  position: relative;
  transform: translateZ(0);
}

#canvasWrapper {
  position: relative;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

#canvasContainer {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.element-drag-container {
  z-index: 10 !important;
}

.element-dragenter {
  .xpp-paper-obj,
  .xpp-paper-paper-title-bg,
  path {
    pointer-events: none !important;
  }
  .xpp-paper-paper-box:not(.xpp-select-active) {
    pointer-events: auto !important;
  }
  .ui-resizable-handle {
    opacity: 0 !important;
  }
}

.remove-path-cursor {
  cursor: url(/img/eraser.gif), url(/img/eraser.cur), default !important;
  & + .xpp-paper-drawing-svg {
    z-index: -1 !important;
  }
  @extend .element-dragenter;
  .ui-resizable-handle {
    display: none;
  }
  .xpp-paper-obj {
    cursor: inherit;
    &.xpp-paper-paper-box {
      pointer-events: none !important;
    }
  }
  .xpp-paper-path-box {
    &::before {
      content: none;
    }
  }
  path {
    pointer-events: auto !important;
    cursor: inherit;
  }
}

.paper-add-page-box {
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
  .paper-add-page-btn {
    flex: none;
    width: 112px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #dbdee6;
    cursor: pointer;
    span,
    i {
      vertical-align: middle;
    }
    span {
      margin-left: 5px;
    }
  }
}

#elementCheckBox {
  opacity: 0;
  pointer-events: none !important;
  position: fixed !important;
  z-index: 1000;
  outline: 0;
  width: 0;
  height: 0;
  border: 1px solid #f5a623;
  background: rgba(245, 166, 35, 0.4);
}

#ItalyCannon {
  pointer-events: none;
  position: fixed;
  top: 56px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(171, 171, 171, 0.8);
  padding: 0;
  &:before {
    content: "";
    display: block;
    height: 100%;
    margin: 0 auto;
    width: 600px;
    background: url("/img/draghere_ic.png") center/100% no-repeat;
  }
}

#dropFileHelper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  border: 3px dashed rgba(0, 0, 0, 0.6);
  width: 10vw;
  height: 10vw;
  background-size: 40%;
  background-position: center;
  background-image: url("/img/context_plus_k.png");
  background-repeat: no-repeat;
}

#dropFileProgress {
  pointer-events: none;
  position: fixed;
  z-index: 11;
  top: 56px;
  left: 0;
  width: 100vw;
  justify-content: center;
}
#dropFileProgress > div {
  background-color: #33344d;
  padding: 12px 32px;
  color: #ffffff;
  font-size: 14px;
  border-radius: 3px;
  position: relative;
}
.xpp-file-progress-close {
  pointer-events: auto;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 26px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  cursor: pointer;
  color: #858c93;
}
.xpp-file-progress-tit {
  font-weight: bold;
  text-align: center;
  max-width: 80%;
  margin: 0 auto 18px;
}
.xpp-file-progress-body {
  font-size: 13px;
  span {
    font-size: 1em;
  }
  em {
    font-style: normal;
    font-size: 0.9em;
    margin-left: 0.5em;
    opacity: 0.6;
    margin-right: 0.8em;
  }
  .xpp-file-progress-box {
    width: 360px;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #636478;
  }
  .xpp-file-progress-load {
    width: 0;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 6px 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: width 0.2s;
    border-radius: 8px;
  }
  .xpp-file-progress-scale {
    width: 40px;
    color: #fff;
    font-weight: 600;
    margin-left: 1em;
  }
}
#updataFiles {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  top: -100px;
  left: -100px;
  z-index: -1;
}
#xppFileDownload {
  width: 0;
  height: 0;
  position: fixed;
  top: -100px;
  left: -10px;
  opacity: 0;
  z-index: -1;
}

#modifiedInfoFixed {
  position: fixed;
  top: 101vh;
  z-index: 3;
  padding: 14px;
  opacity: 0;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  pointer-events: none;
  div:first-child {
    margin-right: 12px;
    width: 26px;
    height: 26px;
  }
  div:last-child {
    width: 250px;
  }
  p {
    height: 20px;
    font-size: 14px;
    line-height: 20px;
  }
  b {
    font-weight: 700;
    color: #373839;
  }
  span {
    color: #9197a3;
    font-weight: 500;
  }
  &.modified-show {
    transition: opacity 0s ease-in 0.6s;
    opacity: 1;
  }
}

#xppToolbarContainer {
  position: fixed;
  width: 595px;
  height: 32px;
  top: 70px;
  left: calc(50vw - 725px / 2);
  z-index: 10;
  padding: 10px 15px;
  background-color: #414a63;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.4);
  border-radius: 3px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  .ql-picker {
    color: #fff;
  }
  .ql-stroke {
    stroke: #fff;
  }
  .ql-fill {
    fill: #fff;
  }
  .ql-picker-label,
  .ql-picker-item {
    text-align: center;
    border: none;
    outline: none;
    &.ql-active,
    &:hover {
      color: #fff;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .ql-font {
    .ql-picker-item {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .ql-bold,
  .ql-italic,
  .ql-underline,
  .ql-strike {
    opacity: 0.4;
    &.ql-active {
      opacity: 1;
    }
  }
  button {
    width: 28px;
    height: 28px;
  }
  .ql-picker-options {
    background-color: #414a63;
    padding: 0;
  }
  .ql-selected {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
  }
  .ql-color-picker {
    .ql-picker-options {
      padding: 10px;
      width: 262px;
    }
    .ql-picker-item {
      width: 36px;
      height: 26px;
      line-height: 1;
      padding: 0;
    }
  }
}

.ql-container,
.history-center,
.xpp-paper-editable-text {
  border: none;
  font-family: inherit;
  overflow: hidden;
  u {
    text-decoration: underline;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  s {
    text-decoration: line-through;
  }
  a {
    position: relative;
    z-index: 2;
    color: #06c;
    text-decoration: underline;
  }
  ul,
  ol {
    padding-left: 0;
  }
  .ql-editor {
    padding: 0;
    overflow: visible;
  }
  ul[data-checked] {
    li {
      &::before {
        content: "";
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: inset 0 1px 2px 2px rgba(0, 0, 0, 0.4);
        border-radius: 0.18em;
        width: 1em;
        height: 1em;
        vertical-align: bottom;
        margin-bottom: 0.15em;
        position: relative;
        z-index: 2;
      }
    }
  }

  ul[data-checked="true"] {
    li {
      color: rgba(0, 0, 0, 0.4) !important;
      &::before {
        background: rgba(95, 95, 95, 0.5)
          url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCA4IDYiPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTIuMyA1LjY2NGwtMi0xLjk4N2EuOTU2Ljk1NiAwIDAgMSAwLTEuMzkyLjk3Ljk3IDAgMCAxIDEuNCAwTDMgMy41NzYgNi4zLjI5OGEuOTcuOTcgMCAwIDEgMS40IDBjLjQuMzk4LjQuOTk0IDAgMS4zOTFsLTQgMy45NzVjLS40LjM5Ni0uOS40OTctMS40IDB6Ii8+PC9zdmc+IA==)
          no-repeat center;
        background-size: 70%;
        opacity: 0.5;
      }
    }
    strong,
    em {
      opacity: 0.5;
    }
  }
}

.xpp-size-select {
  .ql-picker-item,
  .ql-picker-label {
    &::before {
      content: attr(data-value) !important;
    }
  }
  .ql-picker-item {
    height: 20px;
    line-height: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    box-sizing: content-box;
    font-size: 12px;
    &::before {
      font-size: inherit;
      opacity: 0.6;
    }
  }
}

.xpp-paper-mousemove-record {
  position: absolute;
  overflow: hidden;
  z-index: 1001;
  pointer-events: none;
  width: auto;
  height: auto;
  background-color: transparent;
  color: #fff;
  background-repeat: no-repeat;
  background-position: top left;
  background-image: url(/img/cursor_default.gif);
  background-size: 60px;
  padding-top: 27px;
  padding-left: 14px;
  &.xpp-trajectory {
    transition: top 0.3s ease-in-out, left 0.3s ease-in-out;
  }
  & > div {
    width: auto;
    height: auto;
    padding: 6px 13px;
    display: inline-block;
    font-size: 0;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
  }
  .xpp-user-photo {
    flex: none;
    width: 36px;
    height: 36px;
    font-size: 0;
  }
  span {
    flex: none;
    line-height: 42px;
    font-size: 26px;
    font-weight: 700;
    color: #fff;
    margin-left: 0.5em;
    white-space: nowrap;
  }
}
</style>
