<template>
  <div class="xpp-paper-page" ref="paper" :style="paperPageSize.width">
    <div class="xpp-paper-head display-flex">
      <div class="xpp-paper-head-left" :data-empty="!paperInfo.pageTitle">
        <b @click="pageTitEdit">{{ paperInfo.pageTitle || "输入页面标题" }}</b>
        <span>#{{ paperPageIndex + 1 }}</span>
      </div>
      <div class="xpp-paper-head-right display-flex">
        <div
          class="xpp-page-menu-btn"
          :class="{
            'active-btn':
              dialogElementShow.pageBgEdit &&
              paperPageEditDate.index === paperPageIndex
          }"
          data-type="pageBgEdit"
          @click="showPageMoreMenu"
        >
          <svg class="svg" width="16" height="16" viewBox="0 0 16 16">
            <path
              class="svg-p"
              fill="#C2C6D1"
              fill-rule="evenodd"
              d="M6 3.95c1.5-1.5 2.8-2 3.2-2 0 .4-.5 1.7-2 3.2-1.5 1.5-2.8 2-3.2 2 .1-.5.5-1.8 2-3.2m9.6 3.3c.3.3.7 1.1.1 2.4-.4.8-1 1.6-1.7 2.3-1.1 1.1-2.5 2-3.6 2-.4 0-.8-.1-1.1-.4l-6.7-4.9c-1.4-1.4.1-4.2 2-6.1 1-1 2.1-1.8 3.1-2.2 1.7-.7 2.6-.2 3 .2l4.9 6.7zM0 13.95c0-1.1 2-4 2-4s2 2.9 2 4-.9 2-2 2-2-.9-2-2z"
            />
          </svg>
        </div>
        <div
          class="xpp-page-menu-btn"
          data-type="pageMoreMenu"
          @click="showPageMoreMenu"
        >
          <i class="el-icon-more"></i>
        </div>
      </div>
    </div>
    <div
      class="xpp-paper-con"
      ref="paperCon"
      :style="[paperInfo.pageStyle, paperPageSize]"
      @mousedown="wholeEntrust"
      @mouseover="pointerEvnetOff"
      @dblclick="dblclickSelection"
    >
      <!-- 存储dom -->
      <div
        class="xpp-paper-dom-box xpp-paper-con-child"
        :id="paperPageId"
        :class="elementDragenter"
        ref="packNode"
        data-type="page"
        :data-index="paperPageIndex"
      >
        <template v-if="xcanvasAcIdx">
          <!--   线条   -->
          <template v-if="paperInfo.svgSequence">
            <div
              :id="item.id"
              class="xpp-paper-obj xpp-paper-path-box"
              v-for="item in paperInfo.svgSequence"
              :key="item.id"
              :data-type="item.type"
              :data-index="paperPageIndex"
              :data-lock="item.lock"
              :style="item.style"
            >
              <svg class="xpp-paper-svg-path">
                <path
                  class="drawing-svg-path"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  :stroke-width="item.strokeInfo.weight"
                  :stroke-opacity="item.strokeInfo.opacity"
                  :stroke="item.strokeInfo.color"
                  style="fill: none;"
                  :transform="item.transform"
                  :d="item.path"
                />
              </svg>
              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- 文本 -->
          <template v-if="paperInfo.textSequence">
            <div
              :id="text.id"
              class="xpp-paper-obj xpp-paper-text-box xpp-paper-editable-box"
              v-for="text in paperInfo.textSequence"
              :key="text.id"
              :style="text.style"
              :data-type="text.type"
              :data-index="paperPageIndex"
              :data-lock="text.lock"
              data-event="edit"
            >
              <div
                class="xpp-paper-text xpp-paper-editable-text ql-editor"
                v-html="text.content"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                v-if="text.commentsTotal"
                class="xpp-comment-bubble"
                :class="{
                  'comment-active':
                    elementCommentInfo.commentId === text.commentId
                }"
                data-event="addComment"
                :data-comments="text.commentId"
                v-text="text.commentsTotal"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- 便签 -->
          <template v-if="paperInfo.noteSequence">
            <div
              :id="note.id"
              class="xpp-paper-obj xpp-paper-note-box xpp-paper-editable-box"
              v-for="note in paperInfo.noteSequence"
              :key="note.id"
              :style="note.style"
              :data-type="note.type"
              :data-index="paperPageIndex"
              :data-lock="note.lock"
              data-event="edit"
            >
              <div
                class="xpp-paper-note xpp-paper-editable-text ql-editor"
                v-html="note.content"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                v-if="note.commentsTotal"
                class="xpp-comment-bubble"
                :class="{
                  'comment-active':
                    elementCommentInfo.commentId === note.commentId
                }"
                data-event="addComment"
                :data-comments="note.commentId"
                v-text="note.commentsTotal"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- paper -->
          <template v-if="paperInfo.paperSequence">
            <div
              :id="item.id"
              class="xpp-paper-obj xpp-paper-paper-box"
              v-for="item in paperInfo.paperSequence"
              :key="item.id"
              :style="item.style"
              :data-type="item.type"
              :data-index="paperPageIndex"
              :data-lock="item.lock"
            >
              <div class="xpp-paper-paper">
                <div class="xpp-paper-paper-preview">
                  <div :style="item.canvasthumb | paperThumb"></div>
                </div>
                <div class="xpp-paper-paper-title">
                  <div
                    class="xpp-paper-paper-title-bg"
                    :data-id="item.id"
                    :data-tit="item.title"
                    @compositionstart="compositionstart"
                    @compositionend="compositionend"
                    @keydown.enter="paperTitleKeyDown"
                    @blur="paperTitleCreat"
                    contenteditable="true"
                    v-text="item.title"
                  ></div>
                </div>
              </div>
              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                v-if="item.commentsTotal"
                class="xpp-comment-bubble"
                :class="{
                  'comment-active':
                    elementCommentInfo.commentId === item.commentId
                }"
                :data-comments="item.commentId"
                data-event="addComment"
                v-text="item.commentsTotal"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- 图片 -->
          <template v-if="paperInfo.imagesSequence">
            <div
              :id="image.id"
              class="xpp-paper-obj xpp-paper-image-box"
              v-for="image in paperInfo.imagesSequence"
              :key="image.id"
              :style="image.style"
              :data-type="image.type"
              :data-index="paperPageIndex"
              :data-lock="image.lock"
            >
              <img v-lazy="image.src" :alt="image.fileName" />
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <template v-if="image.commentsTotal">
                <div
                  class="xpp-comment-bubble"
                  :class="{
                    'comment-active': elementCommentInfo.commentId === key
                  }"
                  v-for="(comment, key) in image.commentsTotal"
                  :key="key"
                  :data-comments="key"
                  :style="comment.style"
                  data-event="addComment"
                  v-text="comment.total"
                ></div>
              </template>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- files -->
          <template v-if="paperInfo.filesSequence">
            <div
              :id="files.id"
              class="xpp-paper-obj xpp-paper-files-box"
              v-for="files in paperInfo.filesSequence"
              :key="files.id"
              :style="files.style"
              :data-type="files.type"
              :data-index="paperPageIndex"
              :data-lock="files.lock"
            >
              <div class="xpp-paper-files-body">
                <div class="xpp-files-info">
                  <div
                    class="xpp-paper-files-type"
                    :data-file="files.fileType"
                  ></div>
                  <div
                    class="xpp-paper-files-name text-overflow"
                    data-event="openFile"
                    :data-key="files.id"
                    v-text="files.fileName"
                  ></div>
                </div>
                <div class="xpp-files-explain">
                  <div
                    class="xpp-files-explain-text"
                    contenteditable
                    v-html="files.explainText || '说明'"
                    @focus="filesExplainFocus"
                    @blur="filesExplainBlur"
                    @paste="inputPreventDefaultPaste"
                  ></div>
                </div>
              </div>
              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                v-if="files.commentsTotal"
                class="xpp-comment-bubble"
                :class="{
                  'comment-active':
                    elementCommentInfo.commentId === files.commentId
                }"
                data-event="addComment"
                :data-comments="files.commentId"
                v-text="files.commentsTotal"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- hyperlink -->
          <template v-if="paperInfo.hyperlinkSequence">
            <div
              :id="item.id"
              class="xpp-paper-obj xpp-paper-hyperlink-box"
              v-for="item in paperInfo.hyperlinkSequence"
              :key="item.id"
              :style="item.style"
              :data-type="item.type"
              :data-index="paperPageIndex"
              :data-lock="item.lock"
            >
              <div class="xpp-paper-hyperlink-body">
                <div class="xpp-hyperlink-tit">
                  <i class="iconfont icon-link"></i
                  ><span class="text-overflow" data-event="openlink">{{
                    item.link
                  }}</span>
                </div>
                <div class="xpp-hyperlink-container">
                  <div class="xpp-hyperlink-thumb" v-if="item.canvasthumb">
                    <div
                      class="xpp-hyperlink-thumb-img"
                      :style="canvasthumb | paperThumb"
                      data-event="openlink"
                    >
                      <i class="iconfont icon-share2"></i>
                      {{ item.link }}
                    </div>
                  </div>
                  <div class="xpp-hyperlink-con">
                    <div
                      class="xpp-hyperlink-textarea"
                      :class="{ 'link-loading': item.linkLoading }"
                      contenteditable
                      @focus="hyperlinkFocus"
                      @blur="hyperlinkBlur"
                      @paste="inputPreventDefaultPaste"
                      v-html="item.content"
                    ></div>
                  </div>
                </div>
              </div>

              <div
                class="ui-resizable-handle ui-resizable-nw"
                data-direction="nw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-ne"
                data-direction="ne"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-sw"
                data-direction="sw"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-se"
                data-direction="se"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-w"
                data-direction="w"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-s"
                data-direction="s"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-e"
                data-direction="e"
              ></div>
              <div
                class="ui-resizable-handle ui-resizable-lock"
                data-event="lock"
              ></div>
              <div
                v-if="item.commentsTotal"
                class="xpp-comment-bubble"
                :class="{
                  'comment-active':
                    elementCommentInfo.commentId === item.commentId
                }"
                data-event="addComment"
                :data-comments="item.commentId"
                v-text="item.commentsTotal"
              ></div>
              <div class="userdrop-select-info"></div>
            </div>
          </template>

          <!-- 辅助线 -->
          <template v-if="paperGridPathActive">
            <div
              class="grid-path grid-path-x"
              v-if="paperGridPathInfo.x"
              :class="paperGridPathInfo.x.class"
              :style="paperGridPathInfo.x.style"
            ></div>
            <div
              class="grid-path grid-path-y"
              v-if="paperGridPathInfo.y"
              :class="paperGridPathInfo.y.class"
              :style="paperGridPathInfo.y.style"
            ></div>
          </template>
        </template>
      </div>

      <!-- svg 绘制区域 -->
      <svg
        class="xpp-paper-drawing-svg xpp-paper-con-child"
        :class="{ active: editToolbarActive === 2 }"
        ref="svgCanvas"
      />

      <!-- 创建新节点 -->
      <div
        class="xpp-paper-con-child"
        :class="creatNodeStatus"
        @click="creatNodeElement"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ossDownload } from "@plugins/aliyunOss";
import { fileTypeVail } from "@plugins/fileUpload";
import { parentLocation, creatElementId } from "@plugins/toolWheel";

export default {
  name: "PaperPage",
  props: {
    paperInfo: {
      type: Object,
      required: true
    },
    paperPageId: {
      type: String,
      required: true
    },
    paperPageIndex: {
      type: Number,
      required: true
    },
    paperPageSize: {
      type: Object,
      required: true
    },
    xAcIdx: {
      type: Number,
      required: true
    }
  },
  computed: {
    paperGridPathActive() {
      return (
        this.paperElementDropStart &&
        this.paperPageNodeGrid.targetId === this.paperPageId
      );
    },
    creatNodeStatus() {
      let dd = {
        active: false
      };
      switch (this.editToolbarActive) {
        case 3:
          dd.active = true;
          dd["xpp-cursor-text"] = true;
          break;
        case 4:
          dd.active = true;
          dd["xpp-cursor-note"] = true;
          break;
        case 7:
          dd.active = true;
          dd["xpp-cursor-paper"] = true;
          break;
      }
      return dd;
    },
    elementDragenter() {
      return {
        "element-dragenter": !!this.paperElementDropStart,
        "element-drag-container":
          this.paperElementDropStart === this.paperPageId,
        "remove-path-cursor": this.activeRemovePath
      };
    },
    xcanvasAcIdx() {
      return this.xAcIdx >= this.paperPageIndex;
    },
    ...mapGetters([
      "paperGroupId",
      "homeViewScale",
      "editToolbarActive",
      "xppUserInfo",
      "paperPageInfo",
      "paperPageNodeGrid",
      "paperGridPathInfo",
      "strokeInfo",
      "textDefalutInfo",
      "noteDefalutInfo",
      "activeRemovePath",
      "paperElementDropStart",
      "dialogElementShow",
      "paperPageEditDate",
      "homeViewScale",
      "paperViewAll",
      "elementCommentInfo",
      "paperOperateMode",
      "quilluserConfig",
      "paperDragOptions"
    ])
  },
  data() {
    return {
      svgPathNum: 0,
      activePaperId: null,
      pointerOff: false
    };
  },
  mounted() {
    this.canvasPathBind(); // 线条绘制
  },
  methods: {
    updataPack(key) {
      let paper = document.getElementById(this.paperPageId);
      let nodrop = paper.getElementsByClassName("xpp-select-nodrop");
      switch (key) {
        case "pageMoreMenu":
          if (this.paperDragOptions.disabled) {
            return;
          }
          break;
        case "pageBgEdit":
          if (nodrop.length) {
            return;
          }
          break;
      }
      this.$UpdataVuexState({
        key: "paperPageEditDate",
        data: {
          id: this.paperPageId,
          index: this.paperPageIndex,
          title: this.paperInfo.pageTitle,
          style: this.paperInfo.pageStyle
        }
      });
      this.$DiaLoginFoUpDate({ key, value: true });
    },
    // 查询paperViewAll数据
    getPaperData({ key, id, type = "update" }) {
      let data = this.paperInfo[key][id];
      this.$AddXppOperationRecord({
        type,
        data
      });
      return data;
    },
    // page 操作菜单
    showPageMoreMenu({ target }) {
      this.$SetPaperPageInfo(this.paperPageIndex);
      this.$UpdataVuexState({
        key: "pagePositionInfo",
        data: target.getBoundingClientRect()
      });
      this.updataPack(target.dataset.type);
      this.$UpdataVuexState({ key: "viewMaskShow", data: true });
    },
    // 更新page标题
    pageTitEdit() {
      this.updataPack("paperPageTitEdit");
    },
    // 鼠标移入
    pointerEvnetOff() {
      if (this.paperElementDropStart) {
        // 重置坐标值
        this.$emit("updataGrid", document.getElementById(this.paperPageId));
      }
    },
    wholeEntrust(event) {
      if (event.button !== 0) {
        return;
      }
      let { target } = event;
      // page内事件委托
      this.$SetPaperPageInfo(this.paperPageIndex);
      switch (target.dataset.event) {
        case "lock":
          event.stopPropagation();
          let parentData = target.parentNode.dataset;
          let data = this.getPaperData({
            key: parentData.type,
            id: target.parentNode.id
          });
          this.$set(data, "lock", !data.lock);
          this.$emit("updata", data);
          break;
        case "openFile":
          event.stopPropagation();
          let info = this.paperInfo.filesSequence[target.dataset.key];
          let type = event.target.previousElementSibling.dataset.file || "";
          if (fileTypeVail(type)) {
            this.$UpdataVuexState({ key: "previewFileInfo", data: info });
            this.$UpdataVuexState({ key: "showFilePreview", data: true });
          } else {
            this.$VerifyXppAllowanceCredit({
              steamConsume: info.fileInfo.size
            }).then(({ status, updateaxios }) => {
              if (status) {
                location.href = ossDownload(info.fileInfo.name);
                updateaxios();
              }
            });
          }
          break;
      }
    },
    dblclickSelection(event) {
      if (!(document.activeElement instanceof HTMLBodyElement)) {
        return;
      }
      if (this.paperOperateMode !== 2 || this.activeRemovePath) {
        return;
      }
      event.stopPropagation();
      let { target } = event;
      // 点击paper时新页面打开链接
      if (target.classList.contains("xpp-paper-paper-box")) {
        return this.paperNavJump(target.id);
      }
      // 双击图片进行预览
      if (target.classList.contains("xpp-paper-image-box")) {
        return this.$XppPicturePreviewShow({
          type: true,
          data: this.paperInfo.imagesSequence[target.id]
        });
      }
      // 双击文本节点进入编辑状态
      let element = parentLocation(target, ".xpp-paper-editable-box");
      if (element) {
        this.$WsSendMsg({
          action: "DISABLEDELEMENT",
          data: {
            ids: [element.id],
            type: true
          }
        });
        return this.$EditorFocusEventBind(element);
      }
      // 双击空白处创建文本节点
      if (target.classList.contains("xpp-paper-dom-box")) {
        event.preventDefault();
        this.creatTextNode(event);
        return;
      }
    },
    parentNodeScreen(target) {
      if (target.id === "canvasWrapper") {
        return false;
      }
      if (target.dataset.drop === "container") {
        return target;
      } else {
        return this.parentNodeScreen(target.parentElement);
      }
    },
    // IM数据传输线格式封装
    objParamsFormat(type, obj) {
      type = type + "Sequence";
      let data = Object.assign(obj, {
        page_id: this.paperPageId,
        page_index: this.paperPageIndex,
        type,
        lock: false
      });
      if (!this.paperInfo[type]) {
        this.paperInfo[type] = {};
      }
      this.$set(this.paperInfo[type], [data.id], data);
      this.$AddXppOperationRecord({
        type: "creat",
        data
      });
      this.$emit("updata", data);
    },
    createPathNode() {
      // 创建线条
      let stroke = this.strokeInfo;
      let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      path.setAttribute("class", "drawing-svg-path");
      path.setAttribute("stroke-linecap", "round");
      path.setAttribute("stroke-linejoin", "round");
      path.setAttribute("stroke-width", stroke.weight + "px");
      path.setAttribute("stroke-opacity", stroke.opacity);
      path.setAttribute("stroke", stroke.color);
      path.setAttribute("style", "fill:none;");
      return path;
    },
    // 画笔事件绑定
    canvasPathBind() {
      let svgCanvas = this.$refs.svgCanvas;
      let path = null;
      let q = "";
      let m = "";
      let l = "";
      let attrCheckpoint = "";
      let mouseupEvent = () => {
        svgCanvas.onmousemove = null;
        if (this.svgPathNum === 0 && path) {
          return (path = null);
        }
        this.svgPathNum = 0;
        path && this.appendSvg(path);
        path = null;
      };
      let mousemoveEvent = event => {
        let attr = path.getAttribute("d");
        if (event.shiftKey) {
          if (!attrCheckpoint) {
            if (this.svgPathNum < 2) {
              attrCheckpoint = attr;
            } else {
              if (q) {
                attrCheckpoint =
                  attr +
                  `Q${q} ${event.offsetX} ${event.offsetY} ` +
                  `M${event.offsetX} ${event.offsetY} `;
              } else {
                attrCheckpoint = attr + `M${m} `;
              }
            }
          }
          l = `${event.offsetX} ${event.offsetY} `;
          path.setAttribute("d", attrCheckpoint + `L${l}`);
          this.svgPathNum = 2;
        } else {
          if (attrCheckpoint) {
            attrCheckpoint = "";
            path.setAttribute("d", attr + `M${l} `);
            q = "";
            this.svgPathNum = 2;
            return;
          }
          this.svgPathNum++;
          q += `${event.offsetX} ${event.offsetY} `;

          if (this.svgPathNum % 2 !== 0) return;
          path.setAttribute("d", attr + `Q${q}`);
          q = "";
          m = `${event.offsetX} ${event.offsetY} `;
        }
      };
      svgCanvas.onmousedown = event => {
        // 鼠标左键事件生效
        if (event.button !== 0) {
          return false;
        }
        if (path) {
          return mouseupEvent();
        }
        q = "";
        attrCheckpoint = "";
        path = this.createPathNode();
        path.setAttribute("d", `M${event.offsetX} ${event.offsetY} `);

        svgCanvas.appendChild(path);
        svgCanvas.onmousemove = mousemoveEvent;
      };
      svgCanvas.onmouseup = event => {
        // 松开左键时移除事件
        if (event.button !== 0) {
          return false;
        }
        mouseupEvent();
      };
    },
    appendSvg(path) {
      let _this = this;
      let bounding = path.getBBox();
      // 边界值修正
      if (bounding.y < 5) {
        bounding.y = 5;
      }
      if (bounding.x < 5) {
        bounding.x = 5;
      }
      let clientRect = path.getBoundingClientRect();
      let weight = this.strokeInfo.weight;
      let width =
        (clientRect.width < weight ? weight : clientRect.width + 5) /
          this.homeViewScale +
        "px";
      let height =
        (clientRect.height < weight ? weight : clientRect.height + 5) /
          this.homeViewScale +
        "px";

      let id = creatElementId("svg");
      this.objParamsFormat("svg", {
        id,
        style: {
          width,
          height,
          top: bounding.y - 5 + "px",
          left: bounding.x - 5 + "px",
          zIndex: "100"
        },
        strokeInfo: JSON.parse(JSON.stringify(_this.strokeInfo)), // 避免对象修改污染
        transform: `translate(-${bounding.x - 5},-${bounding.y - 5})`,
        path: path.getAttribute("d"),
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0
      });
      this.$refs.svgCanvas.innerHTML = "";
    },
    creatNodeElement(event) {
      switch (this.editToolbarActive) {
        case 3:
          this.creatTextNode(event);
          break;
        case 4:
          this.noteCreatNode(event);
          break;
        case 7:
          this.paperCreatNode(event);
          break;
      }
    },
    quillContentFirstBox(content = "<br>") {
      let box = document.createElement("p");
      box.innerHTML = content;
      for (let i in this.quilluserConfig) {
        switch (i) {
          case "font":
            box.style.fontFamily = this.quilluserConfig[i];
            break;
          case "size":
            box.style.fontSize = this.quilluserConfig[i];
            break;
        }
      }
      return box.outerHTML;
    },
    creatTextNode(event, content) {
      this.$SetEditToolbarType(1);
      let id = creatElementId("text");
      this.objParamsFormat("text", {
        id,
        style: {
          top: event.offsetY + "px",
          left: event.offsetX + "px",
          width: content ? "" : this.textDefalutInfo.width + "px",
          height: content ? "" : this.textDefalutInfo.height + "px",
          backgroundColor: this.textDefalutInfo.backgroundColor,
          zIndex: this.textDefalutInfo.zIndex
        },
        commentId: "",
        createComment: "",
        commentsTotal: 0,
        commentHistory: false,
        content: this.quillContentFirstBox(content),
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0
      });

      this.$nextTick(() => {
        this.$SetPaperActiveDom({
          id,
          type: "textSequence",
          index: this.paperPageIndex
        });
        setTimeout(() => {
          !content && this.$EditorFocusEventBind(document.getElementById(id));
        }, 40);
      });
    },
    noteCreatNode(event) {
      this.$SetEditToolbarType(1);
      let id = creatElementId("note");
      this.objParamsFormat("note", {
        id,
        style: {
          top: event.offsetY + "px",
          left: event.offsetX + "px",
          zIndex: this.noteDefalutInfo.zIndex,
          width: this.noteDefalutInfo.width + "px",
          height: this.noteDefalutInfo.height + "px",
          backgroundColor: this.noteDefalutInfo.backgroundColor
        },
        commentId: "",
        createComment: "",
        commentsTotal: 0,
        commentHistory: false,
        content: this.quillContentFirstBox(),
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0
      });
      this.$nextTick(() => {
        this.$SetPaperActiveDom({
          id,
          type: "noteSequence",
          index: this.paperPageIndex
        });
        setTimeout(() => {
          this.$EditorFocusEventBind(document.getElementById(id));
        }, 40);
      });
    },
    compositionstart({ target }) {
      target.dataset.edit = true;
    },
    compositionend({ target }) {
      target.dataset.edit = false;
    },
    paperTitleKeyDown(event) {
      event.preventDefault();
      event.stopPropagation();
      let edit = event.target.dataset.edit;
      if (!edit || edit === "false") {
        return event.target.blur();
      }
    },
    async paperTitleCreat(event) {
      let { target } = event;
      if (target.innerText.trim() === target.dataset.tit) return;
      let id = target.dataset.id;
      let data = this.getPaperData({
        key: "paperSequence",
        id
      });
      data.title = target.innerText;
      data.page_index = this.paperPageIndex;
      let params = new FormData();
      params.append("paperId", id);
      params.append("paperName", target.innerText);
      await this.$axios.post("/dis/p/update", params);
      this.$AddXppOperationRecord({
        type: "updata",
        data
      });
      this.$emit("updata", data);
    },
    async paperCreatNode(event) {
      this.$SetEditToolbarType(1);
      let id = await this.$CreateNewPaper({
        groupId: this.paperGroupId
      });
      this.objParamsFormat("paper", {
        id,
        title: "",
        style: {
          top: event.offsetY + "px",
          left: event.offsetX + "px",
          width: "420px",
          height: "400px",
          zIndex: "10"
        },
        canvasthumb: "",
        canvasthumbup: false,
        commentId: "",
        createComment: "",
        commentsTotal: 0,
        commentHistory: false,
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0
      });
      this.$nextTick(() => {
        let paperBox = document.getElementById(id);
        let paperTit = paperBox.getElementsByClassName(
          "xpp-paper-paper-title-bg"
        )[0];
        paperTit.focus();
      });
    },
    paperNavJump(id) {
      window.open(location.origin + `/paper/${id}`, "_blank", "noopener");
    },
    hyperlinkFocus(event) {
      let parent = parentLocation(event.target, ".xpp-paper-hyperlink-box");
      let thumb = parent.getElementsByClassName("xpp-hyperlink-thumb")[0];
      if (thumb) {
        thumb.style.height = thumb.clientHeight + "px";
      }
      parent.style.height = "auto";
    },
    hyperlinkBlur({ target }) {
      let parent = parentLocation(target, ".xpp-paper-hyperlink-box");
      let data = this.getPaperData({
        key: "hyperlinkSequence",
        id: parent.id
      });
      data.style.height = parent.clientHeight + "px";
      data.content = target.innerHTML;
      let thumb = parent.getElementsByClassName("xpp-hyperlink-thumb")[0];
      if (thumb) {
        thumb.style = null;
      }
      this.$emit("updata", data);
    },
    filesExplainFocus(event) {
      let parent = parentLocation(event.target, ".xpp-paper-files-box");
      parent.style.height = "auto";
    },
    filesExplainBlur({ target }) {
      let parent = parentLocation(target, ".xpp-paper-files-box");
      let data = this.getPaperData({
        key: "filesSequence",
        id: parent.id
      });
      data.style.height = parent.clientHeight + "px";
      data.explainText = target.innerHTML;
      this.$emit("updata", data);
    },
    inputPreventDefaultPaste(event) {
      event.preventDefault();
      let paste = event.clipboardData || window.clipboardData;
      document.execCommand(
        "insertHTML",
        false,
        paste.getData("text/plain").replace(/\r?\n|\r/g, "<br>")
      );
    },
    ...mapMutations([
      "$SetPaperPageInfo",
      "$SetEditToolbarType",
      "$SetPaperActiveDom",
      "$DiaLoginFoUpDate",
      "$UpdataVuexState",
      "$AddXppOperationRecord",
      "$XppPicturePreviewShow"
    ]),
    ...mapActions([
      "$WsSendMsg",
      "$CreateNewPaper",
      "$EditorFocusEventBind",
      "$VerifyXppAllowanceCredit"
    ])
  }
};
</script>

<style lang="scss" scoped>
.xpp-paper-page {
  position: relative;
  padding-top: 0.1px;
  margin-bottom: 40px;
  display: inline-block;
}

/* paper header */
.xpp-paper-head {
  height: 120px;
  line-height: 120px;
  min-height: 120px;
  display: flex;
  align-items: center;
  padding-left: 1.1em;

  b {
    color: #373839;
    font-size: 48px;
    font-weight: 600;
    padding-left: 0.6em;
    padding-right: 0.6em;
    flex: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }

  span {
    margin-left: 1.2em;
    color: #c2c6d1;
    font-weight: 300;
    font-size: 38px;
  }
}
[data-empty="true"] {
  b {
    color: #c2c6d1;
  }
}
.xpp-paper-head-left,
.xpp-paper-head-right {
  flex: none;
}

.xpp-page-menu-btn {
  flex: none;
  height: 120px;
  width: 120px;
  font-size: 48px;
  text-align: center;
  cursor: pointer;
  position: relative;
  opacity: 0.6;
  color: #c2c6d1;

  &.active-btn {
    path {
      fill: #e49a22;
    }
  }
  * {
    pointer-events: none !important;
  }
  svg {
    width: 100%;
    height: 100%;
    transform: scale(0.4);
  }

  i {
    font-size: inherit;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
  }
}

/* paper content */
.xpp-paper-con {
  position: relative;
  height: calc(100% - 120px);
  box-shadow: 0 0 16px 0 #d4d9dd;
  background-color: #fff;
  border-radius: 18px;
  transition: background-color 0.3s;

  .xpp-paper-con-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    /* 激活后调整显示层级 */
    &.active {
      z-index: 1000001;
      opacity: 1;
    }
  }

  /* 存放数据节点的元素 */
  .xpp-paper-dom-box {
    z-index: 6;
    opacity: 1;
  }
}

.xpp-cursor-text {
  cursor: text;
}

.xpp-cursor-note {
  cursor: url(/img/cursor_postit.gif), url(/img/cursor_postit.cur), default !important;
}

.xpp-cursor-paper {
  cursor: url(/img/cursor_folder.gif), url(/img/cursor_folder.cur), default !important;
}

.xpp-paper-path-box {
  pointer-events: none;
  &.xpp-select-active,
  &.svg-active {
    pointer-events: auto;
  }
  path {
    pointer-events: painted;
  }
}

/* 存放 svg 路径 */
.xpp-paper-drawing-svg {
  z-index: 1;
  cursor: crosshair;

  path {
    pointer-events: none;
  }
}

.xpp-paper-text-box {
  .xpp-paper-text {
    cursor: text;
    &:focus + .ui-resizable-handle {
      opacity: 0;
    }
  }
  a {
    pointer-events: auto;
  }
}

.xpp-paper-paper-title-bg {
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &:focus {
    background-color: #ffffff;
    border: 1px solid rgb(201, 208, 213);
    cursor: text;
  }
}

.xpp-paper-files-box {
  .xpp-paper-files-name {
    &:hover {
      text-decoration: underline;
      color: #775aec;
    }
  }
}

// 节点上评论角标
.xpp-comment-bubble {
  position: absolute;
  bottom: -10px;
  right: 60px;
  z-index: 1;
  transform: scale(2);
  cursor: pointer;
  pointer-events: auto;
  height: 20px;
  padding: 0 0.5em;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border: 2px solid #fff;
  border-radius: 4px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  &.comment-active {
    background-color: #f5a623;
  }
}

.xpp-paper-image-box {
  .xpp-comment-bubble {
    bottom: auto;
    right: auto;
  }
}

.xpp-paper-hyperlink-box {
  .xpp-hyperlink-tit {
    span {
      &:hover {
        text-decoration: underline;
        color: #775aec;
      }
    }
  }
  .xpp-hyperlink-textarea {
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
    &:focus {
      background-color: #fff;
      border: 1px solid #c9d0d5;
    }
  }
  &[data-lock="true"] {
    .xpp-hyperlink-textarea {
      pointer-events: none;
    }
  }
}
.link-loading {
  font-size: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
  &:before {
    font-family: "iconfont";
    flex: none;
    content: "\e8fd";
    animation: iconRotate 0.5s linear infinite;
    font-size: 30px;
    width: 38px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    color: #333;
  }
}
</style>
