<template>
  <div class="edit-toolbar" :style="edittoolChild">
    <ul class="edit-list" @click="editEvent">
      <li
        class="edit-item"
        :class="{ active: item.id === editToolbarActive }"
        v-for="item in edittoolbar"
        :key="item.id"
        :data-id="item.id"
        :data-disabled="item.disabled"
      >
        <div class="toolbar-icon" v-if="item.svg">
          <i class="iconfont" :class="item.svg"></i>
        </div>
        <div class="toolbar-icon" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="30"
            viewBox="-3 -4 34 35"
          >
            <g stroke="none" stroke-width="2" fill="none" fill-rule="evenodd">
              <g transform="translate(-22.000000, -456.000000)">
                <g transform="translate(9.000000, 106.000000)">
                  <g transform="translate(14.000000, 351.000000)">
                    <rect
                      stroke="#4E586F"
                      stroke-width="2"
                      x="0"
                      y="6"
                      width="24"
                      height="16"
                      rx="2"
                    />
                    <circle
                      stroke="#4E586F"
                      fill="#F5A623"
                      cx="22.5"
                      cy="5.5"
                      r="6"
                    />
                    <path
                      fill="#fff"
                      d="M21.8,4.8 L21.8,2.7 C21.8,2.31340068 22.1134007,2 22.5,2 C22.8865993,2 23.2,2.31340068 23.2,2.7 L23.2,4.8 L25.3,4.8 C25.6865993,4.8 26,5.11340068 26,5.5 C26,5.88659932 25.6865993,6.2 25.3,6.2 L23.2,6.2 L23.2,8.3 C23.2,8.68659932 22.8865993,9 22.5,9 C22.1134007,9 21.8,8.68659932 21.8,8.3 L21.8,6.2 L19.7,6.2 C19.3134007,6.2 19,5.88659932 19,5.5 C19,5.11340068 19.3134007,4.8 19.7,4.8 L21.8,4.8 Z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div class="toolbar-label">
          <span>{{ item.title }}</span>
          <span>快捷键：{{ item.keys }}</span>
        </div>
      </li>
    </ul>
    <div class="more-edit"></div>
    <div class="xpp-addembed_form" v-if="editToolbarActive === 5">
      <div class="display-flex">
        <input
          ref="xppAddembedFormInput"
          type="text"
          placeholder="输入一个网址或链接"
          v-model="xppAddembedFormLink"
          @keydown.enter="xppAddembedForm"
          @blur="xppAddembedFormBlur"
        />
        <span class="iconfont icon-right1" @click="xppAddembedForm"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaperToolbar",
  computed: {
    edittoolChild() {
      return `--child: ${this.edittoolbar.length}`;
    },
    edittoolbar() {
      return [
        {
          id: 1,
          keys: "1",
          title: "选择工具",
          svg: "icon-pointer",
          disabled: false
        },
        {
          id: 2,
          keys: "2",
          title: "画笔功能",
          svg: "icon-edit",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 3,
          keys: "3",
          title: "文本工具",
          svg: "icon-text",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 4,
          keys: "4",
          title: "便利贴",
          svg: "icon-note",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 5,
          keys: "5",
          title: "插入链接",
          svg: "icon-link",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 6,
          keys: "6",
          title: "上传文件",
          svg: "icon-updata",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 7,
          keys: "7",
          title: "新建paper",
          disabled: this.paperOperateMode !== 2
        },
        {
          id: 8,
          keys: "8",
          title: "添加评论",
          svg: "icon-comment",
          disabled: this.paperOperateMode === 1
        },
        {
          id: 9,
          keys: "9",
          title: "画板",
          svg: "icon-canvas",
          disabled: this.paperOperateMode === 1
        }
      ];
    },
    ...mapGetters([
      "editToolbarActive",
      "drawingOpen",
      "paperOperateMode",
      "homeViewScale",
      "lastEventCoordinate"
    ])
  },
  watch: {
    editToolbarActive(val) {
      if (val === 5) {
        this.$nextTick(() => {
          this.xppAddembedFormLink = "";
          this.$refs.xppAddembedFormInput.focus();
        });
      }
    }
  },
  data() {
    return {
      xppAddembedFormState: false,
      xppAddembedFormLink: ""
    };
  },
  methods: {
    editEvent(event) {
      let id = event.target.dataset.id;
      this.$emit("toolbarNotification", id);
    },
    xppAddembedForm() {
      this.xppAddembedFormState = true;
      let { top, left } = this.lastEventCoordinate;
      this.$emit("creatLink", {
        top: top + "px",
        left: left + "px",
        link: this.xppAddembedFormLink,
        content: this.xppAddembedFormLink
      });
      this.$emit("toolbarNotification", 1);
    },
    xppAddembedFormBlur() {
      setTimeout(() => {
        if (this.xppAddembedFormState) {
          return;
        } else {
          this.xppAddembedFormState = false;
          this.$emit("toolbarNotification", 1);
        }
      }, 200);
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-toolbar {
  --height: 48px;
  position: fixed;
  z-index: 11;
  left: 0;
  width: $edit-toolbar-width;
  top: calc(50vh - (var(--child) * (var(--height) + 12px) / 2));
}

.edit-list {
  padding: 6px 0;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  box-shadow: 0 -1px 22px -1px rgba(133, 133, 133, 0.5);
  border-radius: 4px;

  .edit-item {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    div {
      pointer-events: none;
    }
    &.active {
      .toolbar-icon {
        background-color: #4e586f !important;
        color: #ffffff !important;
      }
      rect {
        stroke: #fff;
      }
      path {
        fill: #fff;
      }
    }
    &:hover {
      .toolbar-icon {
        background-color: rgba(0, 0, 0, 0.1);
      }
      .toolbar-label {
        opacity: 1;
      }
    }
    .toolbar-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 36px;
      height: 36px;
      border-radius: 100%;
      transition: opacity 0.3s;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .toolbar-label {
      flex: none;
      position: absolute;
      opacity: 0;
      font-size: 12px;
      color: #ffffff;
      z-index: 10000;
      width: auto;
      left: 65px;
      border-radius: 4px;
      padding: 8px 12px;
      background: #373839;
      line-height: 1.8;
      span {
        display: block;
        white-space: nowrap;
      }
      &:after {
        content: "";
        top: calc(50% - 10px);
        border-width: 10px 10px 10px 0;
        left: -7px;
        border-color: transparent #373839;
        transition: border-color 0.2s;
        position: absolute;
        border-style: solid;
      }
    }
  }
}

[data-disabled="true"] {
  opacity: 0.5;
  pointer-events: none;
}
.xpp-addembed_form {
  position: absolute;
  z-index: 10;
  top: 199px;
  left: 56px;
  padding: 20px;
  width: 314px;
  background-color: #fff;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  border-radius: 4px;
  input {
    flex: auto;
    box-sizing: border-box;
    font-size: 14px;
    padding: 0.55em 1em;
    border: 1px solid #dbdee6;
    height: 40px;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 0 0 1px transparent;
    line-height: 1.5;
    font-weight: 500;
    transition: color 0.2s, border 0.2s, background 0.2s, box-shadow 0.2s;
    &:focus {
      background-color: #fff;
      border-color: #f5a623;
      & + span {
        color: #fff;
        background-color: #f5a623;
        border-color: #f5a623;
      }
    }
  }
  span {
    border-left: 0;
    width: 40px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    padding: 0;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: 0.2s;
    color: #c2c6d1;
    border: 1px solid #dbdee6;
    background-color: #ebeef2;
    box-shadow: -12px 0 6px -3px #fff;
  }
}

@media (max-height: 640px) {
  .edit-toolbar {
    top: 60px;
  }
}
</style>
