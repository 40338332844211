<template>
  <div id="paperCatalogContainer">
    <div class="catalog-title"><span>选项</span></div>
    <div class="catalog-btn-option">
      <span class="catalog-btn-item">展开</span>
      <span class="catalog-btn-item">折叠</span>
    </div>
    <div class="catalog-body">
      <ul class="catalog-list" v-if="list.length > 0">
        <li
          class="catalog-list-item"
          v-for="(item, index) in list"
          :key="index"
          :class="{
            'ac-check': paperPageInfo.page_id === item.page_id,
            'open-cc': activeitem === index
          }"
        >
          <div class="catalog-item-label">
            <i class="iconfont icon-xiangxia" @click="spreadItem(index)"></i>
            <span @click="$PaperScrollToIndex(item.page_id)">{{
              item.pageTitle ? item.pageTitle : "页面 " + (index + 1)
            }}</span>
            <u>{{ index + 1 }}</u>
          </div>
          <ul class="catalog-item-child">
            <li
              class="catalog-child-item"
              v-for="child in item.paperSequence"
              :key="child.id"
              @click="focusingPaper(child)"
            >
              <span>{{ child.title }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "PaperCatalog",
  props: {
    list: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(["paperPageInfo"])
  },
  data() {
    return {
      activeitem: null
    };
  },
  methods: {
    spreadItem(index) {
      if (this.activeitem === index) {
        this.activeitem = null;
      } else {
        this.activeitem = index;
      }
    },
    focusingPaper(item) {
      localStorage.setItem(
        "notiNavigatorInfo",
        JSON.stringify({
          elementId: item.id,
          elementType: "focusing"
        })
      );
      this.$NavToTargetElement();
    },
    ...mapMutations(["$PaperScrollToIndex"]),
    ...mapActions(["$NavToTargetElement"])
  }
};
</script>

<style lang="scss" scoped>
#paperCatalogContainer {
  position: absolute;
  width: 300px;
  top: 0;
  left: -300px;
  background-color: #fff;
  overflow: hidden;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  border-radius: 3px;
}
.catalog-title {
  height: 28px;
  line-height: 28px;
  padding: 13px 20px;
  border-bottom: 1px solid #dbdee6;
  color: #373839;
  font-size: 16px;
  font-weight: 500;
}
.catalog-btn-option {
  height: 28px;
  line-height: 28px;
  span {
    font-size: 12px;
    padding: 0;
    color: #f5a623;
    margin-left: 20px;
    cursor: pointer;
    font-weight: 500;
  }
}
.catalog-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: auto;
  min-height: 260px;
  max-height: 60vh;
  padding: 15px 20px;
}
.catalog-list-item {
  padding: 0;
  margin-bottom: 7px;
  line-height: 30px;
  &.ac-check {
    .catalog-item-label span {
      color: #f7bc03;
      background-image: url(/img/nav-canvas-active-icon.svg);
    }
  }
  &.open-cc {
    .icon-xiangxia {
      color: #c2c6d1;
      transform: rotate(0);
    }
    .catalog-item-child {
      max-height: 1000px;
      overflow: auto;
    }
  }
}
.catalog-item-label {
  display: flex;
  align-items: center;
  .icon-xiangxia {
    transform: rotate(-90deg);
    transition: transform 0.2s;
    width: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  }
  span {
    flex: auto;
    color: #9197a3;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 25px;
    margin-left: 0.5em;
    background: url(/img/nav-canvas-icon.svg) no-repeat center left/20px 16px;
  }
  u {
    font-size: 12px;
    font-weight: 700;
    text-align: right;
    color: #373839;
  }
}
.catalog-item-child {
  padding-left: 25px;
  color: #9197a3;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s;
}
.catalog-child-item {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  height: 33px;
  line-height: 36px;
  padding-left: 28px;
  align-items: center;
  border-radius: 4px;
  background: url(/img/nav-folder-icon.svg) no-repeat 10px center/16px;
  overflow: hidden;
  &:hover {
    background-color: #f5f7fa;
    color: #373839;
    background-image: url(/img/nav-folder-hover-icon.svg);
    cursor: pointer;
  }
  span {
    flex: auto;
    color: inherit;
    font-size: 12px;
    height: 33px;
    line-height: 33px;
    padding-left: 0.5em;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
