<template>
  <div class="xpp-view-ctrl">
    <div class="xpp-operation-record xpp-option-box">
      <div
        class="xpp-option-btn iconfont icon-chexiao"
        :class="{ active: xppOperationRecord[0].length }"
        data-type="revoke"
        @click="operation"
      ></div>
      <div
        class="xpp-option-btn iconfont icon-chexiao"
        :class="{ active: xppOperationRecord[1].length }"
        data-type="recovery"
        @click="operation"
      ></div>
    </div>
    <div class="xpp-view-zoom-box xpp-option-box">
      <span class="xpp-option-btn el-icon-minus" @click="viewZoomMinus"></span>
      <span class="xpp-option-btn" @click="viewZoomReset">{{ zoom }}</span>
      <span class="xpp-option-btn el-icon-plus" @click="viewZoomAdd"></span>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PaperViewZoom",
  computed: {
    zoom() {
      return (
        Math.floor((1 - (this.homeViewScaleReset - this.homeViewScale)) * 100) +
        "%"
      );
    },
    viewZoomHistory() {
      return this.homeViewScale;
    },
    ...mapGetters([
      "homeViewScale",
      "homeViewScaleReset",
      "paperViewZoomInfo",
      "xppOperationRecord"
    ])
  },
  data() {
    return {
      viewZoomPercentage: 1
    };
  },
  methods: {
    viewZoomAdd() {
      let num = Math.floor((this.homeViewScale + 0.1) * 100) / 100;
      this.containerScrollOffset(num);
      this.$SetViewScale(num);
    },
    viewZoomMinus() {
      let num = ((this.homeViewScale - 0.1) * 100) / 100;
      if (num <= 0.1) {
        num = 0.1;
      }
      this.containerScrollOffset(num);
      this.$SetViewScale(num);
    },
    viewZoomReset() {
      this.$SetViewScale(this.homeViewScaleReset);
    },
    operation({ target }) {
      this.$emit("operation", target.dataset.type);
    },
    containerScrollOffset(num) {
      let container = document.getElementById("paperContainer");
      let width = this.paperViewZoomInfo.width * (num - this.homeViewScale);
      let height = this.paperViewZoomInfo.height * (num - this.homeViewScale);
      container.scrollTo(
        container.scrollLeft + width / 2,
        container.scrollTop + height / 2
      );
    },
    ...mapMutations(["$SetViewScale"])
  }
};
</script>

<style lang="scss" scoped>
.xpp-view-ctrl {
  position: fixed;
  left: 10px;
  bottom: 20px;
  width: 130px;
  z-index: 20;
  .xpp-option-box {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    height: 36px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 3px;
    overflow: hidden;
  }
  .xpp-option-btn {
    flex: none;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #373839;
    cursor: pointer;
  }
}
.xpp-operation-record {
  margin-bottom: 10px;
  width: 72px;
  .xpp-option-btn {
    font-size: 16px;
    opacity: 0.5;
    pointer-events: none;
    &:last-child {
      border-right: 1px solid rgb(233, 235, 236);
      transform: rotateY(180deg);
    }
    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.xpp-view-zoom-box {
  .xpp-option-btn {
    &:nth-child(2) {
      flex: auto;
      border-left: 1px solid rgb(233, 235, 236);
      border-right: 1px solid rgb(233, 235, 236);
    }
  }
}
</style>
