<template>
  <div id="PicturePreview" v-if="showPicturePreview">
    <div class="preview-close" @click="$XppPicturePreviewShow()">
      <i class="el-icon-close"></i>
    </div>
    <div class="preview-scale" ref="previewScaleDom">{{ previewScale }}</div>
    <div
      class="preview-container"
      v-loading="imageScale === 0"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @mousedown="previewContainerEvent"
      @mousewheel="previewMousewheel"
    >
      <img
        :src="imageActive.src"
        ref="imageDom"
        :style="imageStyle"
        @load="imageLoad"
        @dragstart.prevent.stop
      />
    </div>
    <div class="preview-footer">
      <div class="preview-title">
        <div class="preview-image-name">
          <span>{{ imageActive.fileName }}</span>
          <span>{{ "(" + imageSize + ")" }}</span>
        </div>
      </div>
      <div class="preview-toolbar" @click="toolbar">
        <div data-type="enlarge">
          <i class="iconfont icon-jia" data-label="放大"></i>
        </div>
        <div data-type="narrow">
          <i class="iconfont icon-jian" data-label="缩小"></i>
        </div>
        <div data-type="reload">
          <i class="iconfont icon-xuanzhuan" data-label="重新加载"></i>
        </div>
        <div data-type="prev">
          <i
            class="iconfont icon-jiantouyou icon-mirror"
            data-label="上一张"
          ></i>
        </div>
        <div data-type="next">
          <i class="iconfont icon-jiantouyou" data-label="下一张"></i>
        </div>
        <div data-type="anti-clockwise">
          <i class="iconfont icon-chexiao" data-label="逆时针旋转90°"></i>
        </div>
        <div data-type="clockwise">
          <i
            class="iconfont icon-chexiao icon-mirror"
            data-label="顺时针旋转90°"
          ></i>
        </div>
        <div data-type="download">
          <i class="iconfont icon-download" data-label="下载图片"></i>
        </div>
      </div>
      <div class="preview-navbar">
        <ul :style="imageNavOffset">
          <li
            v-for="(item, index) in imageList"
            :key="item.id"
            :class="{ active: index === targetIndex }"
            :data-index="index"
            @click="previewNavbar"
          >
            <div :style="{ backgroundImage: `url(${item.src})` }"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { ossDownload } from "@plugins/aliyunOss";

export default {
  name: "PicturePreview",
  computed: {
    imageStyle() {
      return {
        opacity: this.imageScale !== 0 ? 1 : 0,
        transform: `scale(${this.imageScale}) rotate(${this.imageRotate}deg) translate(${this.imageTranslate})`
      };
    },
    imageNavOffset() {
      return {
        marginLeft: `-${this.targetIndex * 31 + 13}px`
      };
    },
    previewScale() {
      return Math.floor(this.imageScale * 100) + "%";
    },
    ...mapGetters(["showPicturePreview", "paperViewAll", "triggerImgInfo"])
  },
  watch: {
    showPicturePreview(val) {
      if (val) {
        this.init();
      }
    },
    imageScale(val) {
      if (val !== 0 && !this.previewScaleanimate) {
        this.previewScaleanimate = this.$refs.previewScaleDom.animate(
          [
            {
              opacity: 0
            },
            {
              opacity: 1
            }
          ],
          {
            fill: "forwards",
            easing: "ease",
            duration: 300,
            endDelay: 600
          }
        );
        this.previewScaleanimate.onfinish = () => {
          this.previewScaleanimate && this.previewScaleanimate.cancel();
          this.previewScaleanimate = null;
        };
      }
    }
  },
  data() {
    return {
      imageList: [],
      imageActive: { fileInfo: {} },
      targetIndex: 0,
      imageSize: "",
      imageScale: 0,
      imageRotate: 0,
      imageTranslate: "0, 0",
      imageDragEvent: null,
      imageDragOffset: [],
      previewScaleanimate: null
    };
  },
  methods: {
    init() {
      let { id } = this.triggerImgInfo;
      let imageList = [];
      for (let i = 0; i < this.paperViewAll.length; i++) {
        let item = this.paperViewAll[i];
        for (let o in item.imagesSequence) {
          let data = JSON.parse(JSON.stringify(item.imagesSequence[o]));
          imageList.push(data);
          if (data.id === id) {
            this.$set(this, "imageActive", data);
            this.targetIndex = imageList.length - 1;
          }
        }
      }
      this.imageList = imageList;
    },
    imageLoad() {
      this.imageScaleInit();
    },
    imageScaleInit() {
      let maxH = document.documentElement.clientHeight - 154 - 60;
      let maxW = document.documentElement.clientWidth - 60;
      let { width, height } = this.$refs.imageDom;
      this.imageSize = `${width} x ${height}`;
      this.imageScale = 1;
      if (width >= maxW || height >= maxH) {
        if (width >= height) {
          this.imageScale = Math.floor((maxW / width) * 100) / 100;
        } else {
          this.imageScale = Math.floor((maxH / height) * 100) / 100;
        }
      }
      this.imageRotate = 0;
      this.imageTranslate = "0, 0";
    },
    toolbar({ target }) {
      switch (target.dataset.type) {
        case "enlarge":
          this.imageScale += 0.05;
          break;
        case "narrow":
          if (this.imageScale > 0.05) {
            this.imageScale -= 0.05;
          }
          break;
        case "reload":
          this.imageScaleInit();
          break;
        case "prev":
          if (this.targetIndex > 0) {
            this.targetIndex--;
            this.previewNavbar(this.targetIndex);
          }
          break;
        case "next":
          if (this.targetIndex < this.imageList.length - 1) {
            this.targetIndex++;
            this.previewNavbar(this.targetIndex);
          }
          break;
        case "anti-clockwise":
          this.imageRotate -= 90;
          break;
        case "clockwise":
          this.imageRotate += 90;
          break;
        case "download":
          if (this.imageActive.fileInfo.copyimg) {
            this.$alert("网络转载图片，不可下载到本地");
            return;
          }
          location.href = ossDownload(this.imageActive.fileInfo.name, {
            "content-disposition": `attachment;filename=${this.imageActive.fileName}`
          });
          break;
      }
    },
    previewNavbar() {
      let index;
      let [target] = arguments;
      if (target instanceof MouseEvent) {
        index = target.target.dataset.index - 0;
        this.targetIndex = index;
      } else {
        index = target;
      }
      this.$set(this, "imageActive", this.imageList[index]);
    },
    previewClose({ target }) {
      if (target.className === "preview-container") {
        return this.$XppPicturePreviewShow();
      }
    },
    imageDrag(event) {
      let { clientX, clientY } = this.imageDragEvent;
      let xx = (event.clientX - clientX) / this.imageScale;
      let yy = (event.clientY - clientY) / this.imageScale;
      let [x, y] = this.imageDragOffset;
      this.imageTranslate = `${xx + x}px, ${yy + y}px`;
    },
    imageMouseup() {
      this.imageDragEvent.target.style.transition = null;
      window.removeEventListener("mousemove", this.imageDrag);
      window.removeEventListener("mouseup", this.imageMouseup);
    },
    previewContainerEvent({ target }) {
      if (target.tagName === "IMG") {
        this.imageDragEvent = arguments[0];
        this.imageDragOffset = this.imageTranslate
          .split(",")
          .map(item => item.trim().replace("px", "") - 0);
        target.style.transition = "none";
        window.addEventListener("mousemove", this.imageDrag);
        window.addEventListener("mouseup", this.imageMouseup);
      }
    },
    previewMousewheel(event) {
      event.preventDefault();
      event.stopPropagation();
      if (event.deltaY > 0) {
        if (this.imageScale > 0.05) {
          this.imageScale -= 0.05;
        }
      } else {
        this.imageScale += 0.05;
      }
    },
    ...mapMutations(["$XppPicturePreviewShow"])
  }
};
</script>

<style scoped lang="scss">
#PicturePreview {
  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.icon-mirror {
  transform: rotateY(180deg);
}

.preview-scale {
  pointer-events: none;
  position: fixed;
  top: 40%;
  left: 50%;
  z-index: 3;
  margin-top: -10px;
  margin-left: -25px;
  font-size: 14px;
  line-height: 1;
  padding: 6px 20px;
  width: auto;
  height: auto;
  text-align: center;
  color: #fff;
  border-radius: 30px;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
}

.preview-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: calc(100vh - 154px);
  padding-bottom: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    flex: none;
    cursor: grab;
    transition: all 0.15s ease-out;
  }
}
.preview-close {
  top: 20px;
  right: 20px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 300px;
  background-color: rgba(0, 0, 0, 0.4);
  width: 120px;
  height: 120px;
  font-size: 60px;
  color: #fff;
  text-align: center;
  line-height: 120px;
  opacity: 0.7;
  transition: all 0.2s ease;
  &:hover {
    opacity: 1;
    font-size: 70px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.preview-footer,
.preview-close {
  position: absolute;
  z-index: 2;
}

.preview-footer {
  width: 100%;
  left: 0;
  bottom: 0;
}

.preview-toolbar {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  div {
    position: relative;
    flex: none;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    &:hover {
      background-color: #f7bc03;
    }
    & + div {
      margin-left: 8px;
    }
    .iconfont {
      flex: none;
      pointer-events: none;
    }
  }
}
.preview-navbar {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 0;
  padding-left: 50%;
  ul {
    display: flex;
    padding-top: 1px;
    padding-bottom: 1px;
    transition: margin 0.15s ease-out;
  }
  li {
    flex: none;
    width: 30px;
    height: 50px;
    box-sizing: border-box;
    overflow: hidden;
    margin-left: 1px;
    opacity: 0.5;
    transition: opacity 0.2s ease, border 0.2s ease, margin 0.3s ease;
    cursor: pointer;
    &.active {
      opacity: 1;
      margin-left: 13px;
      margin-right: 13px;
      border-bottom: 4px solid #f5a623;
    }
    &:hover {
      opacity: 0.8;
      border-bottom: 4px solid #fff;
    }
  }
  div {
    background-size: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}
.preview-title {
  width: 100%;
  display: flex;
  justify-content: center;
}
.preview-image-name {
  font-size: 16px;
  line-height: 1;
  max-width: 90%;
  padding: 12px 19px;
  margin: 10px 5%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.76);
  border-radius: 6px;
  &:hover {
    background-color: #444;
    color: #fff;
  }
  span + span {
    margin-left: 1em;
  }
}
</style>
