import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    paperPageSize() {
      let size = {
        width: this.paperViewZoomInfo.width * this.homeViewScale,
        height: this.paperViewZoomInfo.height * this.homeViewScale,
        top: 120 * this.homeViewScale,
        bottom: 40 * this.homeViewScale
      };
      this.$UpdataVuexState({
        key: "paperPageSizeInfo",
        data: size
      });
      return size;
    },
    offsetParent() {
      let { width, height, top, bottom } = this.paperPageSize;
      return {
        width: width + "px",
        height: (height + bottom + top) * (this.paperViewAll.length || 1) + "px"
      };
    },
    viewRatio() {
      return {
        transform: `scale(${this.homeViewScale})`
      };
    },
    ...mapGetters([
      "homeViewScale",
      "paperViewZoomInfo",
      "paperViewAll",
      "slideSidebarOpen"
    ])
  },
  watch: {
    slideSidebarOpen() {
      this.viewChange();
    },
    "paperViewZoomInfo.width"() {
      this.viewChange();
    }
  },
  data() {
    return {
      viewScale: 1,
      timeStamp: 0,
      delayExecution: null
    };
  },
  mounted() {
    window.onresize = () => {
      this.viewChange();
    };
  },
  methods: {
    viewChange(info) {
      if (new Date() - this.timeStamp < 300) {
        clearTimeout(this.delayExecution);
        this.delayExecution = setTimeout(this.viewChange, 300);
        return;
      }
      this.timeStamp = new Date();
      let screenWidth =
        document.getElementById("paperContainer").clientWidth - 60;
      if (this.slideSidebarOpen) {
        screenWidth -= 95;
      }
      let { width } = info || this.paperViewZoomInfo;
      this.viewScale = screenWidth / width;
      this.$SetViewScale(this.viewScale);
    },
    ...mapMutations(["$SetViewScale", "$UpdataVuexState"])
  }
};
