<template>
  <div
    class="xpp-slides-main"
    @click="scrollToIndex"
    @contextmenu.prevent="showContextMenu"
  >
    <div
      class="xpp-slides-page-tit text-overflow"
      v-text="pageInfo.pageTitle"
    ></div>
    <div class="xpp-slides-page-body display-flex">
      <span class="slides-list-index" v-text="pageIndex + 1"></span>
      <div
        class="slides-list-item"
        :class="{
          'slides-list-active': paperPageInfo.page_id === pageInfo.page_id
        }"
        :style="[pageInfo.pageStyle, pageSizePreview]"
        v-loading="!xcanvasAcIdx"
      >
        <div class="page-thumbnail-box" :style="pageZoom">
          <template v-if="xcanvasAcIdx">
            <!-- 文字 -->
            <template v-if="textSequence.length">
              <div
                class="page-thumbnail-element"
                v-for="item in textSequence"
                :key="item.id"
                :style="item.style"
              >
                <div
                  class="xpp-thumbnail-paper-note"
                  v-html="item.content"
                ></div>
              </div>
            </template>
            <!-- 便签 -->
            <template v-if="noteSequence.length">
              <div
                class="page-thumbnail-element"
                v-for="note in noteSequence"
                :key="note.id"
                :style="note.style"
              >
                <div
                  class="xpp-thumbnail-paper-note"
                  v-html="note.content"
                ></div>
              </div>
            </template>
            <!-- paper -->
            <template v-if="paperSequence.length">
              <div
                class="page-thumbnail-element xpp-paper-paper-box"
                v-for="paper in paperSequence"
                :key="paper.id"
                :style="paper.style"
              >
                <div class="xpp-paper-paper">
                  <div class="xpp-paper-paper-preview">
                    <div :style="paper.canvasthumb | paperThumb"></div>
                  </div>
                </div>
              </div>
            </template>
            <!-- svg -->
            <template v-if="svgSequence.length">
              <div
                class="page-thumbnail-element page-thumbnail-path-node"
                v-for="path in svgSequence"
                :key="path.id"
                :style="path.style"
              >
                <svg class="xpp-paper-svg-path">
                  <path
                    class="drawing-svg-path"
                    stroke-linejoin="round"
                    stroke-linecap="round"
                    :stroke-width="path.strokeInfo.weight"
                    :stroke-opacity="path.strokeInfo.opacity"
                    :stroke="path.strokeInfo.color"
                    style="fill: none;"
                    :transform="path.transform"
                    :d="path.path"
                  />
                </svg>
              </div>
            </template>
            <!-- 图片 -->
            <template v-if="imagesSequence.length">
              <div
                class="page-thumbnail-element page-thumbnail-image"
                v-for="image in imagesSequence"
                :key="image.id"
                :style="image.style"
              >
                <img v-lazy="image.src" :alt="image.fileName" />
              </div>
            </template>
            <!-- files -->
            <template v-if="filesSequence.length">
              <div
                class="page-thumbnail-element xpp-paper-files-box"
                v-for="files in filesSequence"
                :key="files.id"
                :style="files.style"
              >
                <div class="xpp-paper-files-body">
                  <div
                    class="xpp-paper-files-type"
                    :data-file="files.fileType"
                  ></div>
                  <div
                    class="xpp-paper-files-name text-overflow"
                    data-event="openFile"
                    :data-key="files.id"
                    v-text="files.fileName"
                  ></div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PageThumbnail",
  props: {
    pageInfo: {
      type: Object,
      required: true
    },
    pageIndex: {
      type: Number,
      required: true
    },
    xAcIdx: {
      type: Number,
      required: true
    }
  },
  computed: {
    pageSizePreview() {
      let { width, height } = this.paperViewZoomInfo;
      /**
       * 120 / h = width / height
       * h = (width / height) * 120
       * */
      return {
        height: (120 * height) / width + "px",
        width: "120px"
      };
    },
    xcanvasAcIdx() {
      return this.xAcIdx >= this.pageIndex;
    },
    pageZoom() {
      let zoom = 120 / this.paperViewZoomInfo.width;
      return {
        transform: `scale(${zoom})`
      };
    },
    textSequence() {
      let list = this.pageInfo.textSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    noteSequence() {
      let list = this.pageInfo.noteSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    imagesSequence() {
      let list = this.pageInfo.imagesSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    filesSequence() {
      let list = this.pageInfo.filesSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    paperSequence() {
      let list = this.pageInfo.paperSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    svgSequence() {
      let list = this.pageInfo.svgSequence;
      let arr = [];
      for (let i in list) {
        arr.push(list[i]);
      }
      return arr;
    },
    ...mapGetters([
      "homeViewScale",
      "slideSidebarOpen",
      "paperPageInfo",
      "paperViewZoomInfo",
      "pagePositionInfo",
      "paperDragOptions"
    ])
  },
  data() {
    return {};
  },
  methods: {
    scrollToIndex() {
      this.$PaperScrollToIndex(this.pageInfo.page_id);
    },
    showContextMenu({ pageX, pageY }) {
      if (this.paperDragOptions.disabled) return;
      this.$SetPaperPageInfo(this.pageIndex);
      this.$UpdataVuexState({
        key: "pagePositionInfo",
        data: {
          width: 0,
          left: pageX,
          bottom: pageY
        }
      });
      this.$UpdataVuexState({
        key: "paperPageEditDate",
        data: {
          id: this.pageInfo.page_id,
          index: this.pageIndex,
          title: this.pageInfo.pageTitle,
          style: this.pageInfo.pageStyle
        }
      });
      this.$DiaLoginFoUpDate({ key: "pageMoreMenu", value: true });
      this.$UpdataVuexState({ key: "viewMaskShow", data: true });
    },
    ...mapMutations([
      "$PaperScrollToIndex",
      "$SetPaperPageInfo",
      "$UpdataVuexState",
      "$DiaLoginFoUpDate"
    ])
  }
};
</script>
<style lang="scss" scoped>
.page-thumbnail-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.xpp-slides-main {
  margin-bottom: 11px;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 8px;
}

.xpp-slides-main * {
  pointer-events: none;
}

.xpp-slides-page-tit {
  padding-left: 26px;
  padding-right: 21px;
  padding-bottom: 4px;
  text-align: center;
  margin-top: 3px;
  line-height: 21px;
  font-weight: 600;
  font-size: xx-small;
  &:empty {
    margin-top: 0;
  }
}

.xpp-slides-page-body {
  padding-right: 14px;
}

.slides-list-index {
  flex: none;
  height: 100%;
  width: 26px;
  text-align: center;
  font-size: 12px;
  color: #8e959a;
}

.slides-list-item {
  flex: auto;
  border-radius: 8px;
  box-shadow: 0px 0px 0 4px #e1e2e9;
  overflow: hidden;
  position: relative;
  &.slides-list-active {
    box-shadow: 0px 0px 0 4px #f5a623;
  }
}

.page-thumbnail-element {
  position: absolute;
}

.xpp-thumbnail-paper-note {
  position: relative;
  box-sizing: border-box;
  font-size: 42px;
  padding: 25px 35px;
  line-height: 1.42;
}

.page-thumbnail-path-node {
  svg {
    width: 100%;
    height: 100%;
    overflow: inherit;
  }
}

.page-thumbnail-image {
  img {
    min-width: 100%;
    min-height: 100%;
  }
}
</style>
