let fileTypeVail = type => {
  let off = false;
  switch (type.toLocaleLowerCase()) {
    case "audio":
    case "video":
    case "ppt":
    case "pptx":
    case "ppsx":
    case "xls":
    case "xlsx":
    case "doc":
    case "docx":
    case "rtf":
    case "pdf":
    case "js":
    case "css":
    case "less":
    case "scss":
    case "xml":
    case "txt":
    case "md":
    case "styl":
      off = true;
      break;
  }
  return off;
};

let fileDataExtract = (id, data) => {
  let element = document.getElementById(id);
  let key =
    element.id ||
    element.getElementsByClassName("xpp-paper-files-name")[0].dataset.key;
  return data[element.dataset.index][element.dataset.type][key];
};

export { fileTypeVail, fileDataExtract };
