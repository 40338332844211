import { render, staticRenderFns } from "./PicturePreview.vue?vue&type=template&id=2bcec054&scoped=true&"
import script from "./PicturePreview.vue?vue&type=script&lang=js&"
export * from "./PicturePreview.vue?vue&type=script&lang=js&"
import style0 from "./PicturePreview.vue?vue&type=style&index=0&id=2bcec054&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bcec054",
  null
  
)

export default component.exports